/* eslint-disable no-use-before-define */
import styled from 'styled-components';
import Slider from 'infinite-react-carousel';
import { calcRem } from 'utils';

const State = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  @media (max-width: 575px) {
    height: auto;
    padding: ${calcRem(64)} ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: auto;
    padding: ${calcRem(32)} ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    align-items: center;
    height: ${calcRem(576)};
    padding: ${calcRem(64)} ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    align-items: center;
    height: ${calcRem(576)};
    padding: ${calcRem(64)} ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    align-items: center;
    height: ${calcRem(576)};
    padding: ${calcRem(64)} ${calcRem(32)};
  }
`;

const Cover = styled.div`
  width: 30%;
  height: auto;

  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 30%;
    padding: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: 30%;
    padding: 0 ${calcRem(16)} 0 0;
  }
`;

const CoverState = styled.div`
  width: 100%;
  height: auto;
`;

const Main = styled.div`
  height: auto;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
   }

  @media (min-width: 576px) and (max-width: 767px) {
    order: 2;
    width: 60%;
    padding: 0 0 0 ${calcRem(16)};
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    order: 2;
    width: 60%;
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 40%;
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: 40%;
    padding: 0 ${calcRem(16)};
  }
`;

const MainState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 575px) {
    align-items: center;
    text-align: center;
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    align-items: center;
    text-align: center;
  }

  @media (min-width: 1200px) {
    align-items: center;
    text-align: center;
  }
`;

const MainTitle = styled.h2`
  color: #ffffff;
  font-weight: 500;

  @media (max-width: 575px) {
  margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(30)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(48)};
  }
`;

const MainDesc = styled.p`
  color: #ffffff;
  line-height: 1.25;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
    font-size: ${calcRem(30)};
  }
`;

const MainRequest = styled.div`
  @media (max-width: 575px) {
    width: ${calcRem(168)};
    height: ${calcRem(52)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }
`;

const Items = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    order: 1;
    width: 40%;
    padding: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    order: 1;
    width: 40%;
    padding: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 30%;
    padding: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: 30%;
    padding: 0 0 0 ${calcRem(16)};
  }
`;

const ItemSlider = styled(Slider)`
  width: 100%;
  height: auto;
`;

export {
  State,
  Cover,
  CoverState,
  ItemSlider,
  Items,
  Main,
  MainDesc,
  MainRequest,
  MainState,
  MainTitle,
};
