import PropTypes from 'prop-types';
import React from 'react';
import { State, Position, Image } from './styled';

const Imagestatic = (props) => {
  const {
    className,
    overlay,
    size,
    source,
  } = props;

  if (!source) {
    return null;
  }

  if (size === '') {
    return (
      <State
        className={className}
        size={size}
      >
        <Image
          fluid={source}
          overlay={overlay}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </State>
    );
  }

  return (
    <State
      className={className}
      size={size}
    >
      <Position>
        <Image
          fluid={source}
          overlay={overlay}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </Position>
    </State>
  );
};

Imagestatic.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
  size: PropTypes.string,
  overlay: PropTypes.bool,
};

Imagestatic.defaultProps = {
  className: null,
  size: '',
  overlay: false,
};

export default Imagestatic;
