import React from 'react';
import { ButtonTheme } from 'components';
import { useIntl } from 'gatsby-plugin-intl';
import { ImageBackground, ImageResponsive } from 'utils';
import {
  State,
  Cover,
  CoverState,
  ItemSlider,
  Items,
  Main,
  MainDesc,
  MainRequest,
  MainState,
  MainTitle,
} from './styled';

const Reward = () => {
  const intl = useIntl();

  return (
    <ImageBackground source="reward-bg--blue.jpg">
      <State>
        <Cover>
          <CoverState>
            <ImageResponsive
              source="reward-cover--hilight.png"
              alt="ALLTOP ASIA Reward"
              fadeIn
            />
          </CoverState>
        </Cover>

        <Main>
          <MainState>
            <MainTitle>
              {intl.formatMessage({ id: 'screen.reward.title' })}
            </MainTitle>

            <MainDesc>
              {intl.formatMessage({ id: 'screen.reward.desc' })}
              <br />
              &quot;
              {intl.formatMessage({ id: 'screen.reward.slogan' })}
              &quot;
            </MainDesc>

            <MainRequest>
              <ButtonTheme
                icon="reward"
                title={intl.formatMessage({ id: 'screen.request.reward' })}
                blank="https://www.alltoppoint.com/"
                type="gold"
              />
            </MainRequest>
          </MainState>
        </Main>

        <Items>
          <ItemSlider
            arrows={false}
            arrowsBlock={false}
            autoplaySpeed={5000}
            dots={false}
            duration={250}
            autoplay
          >
            <div>
              <ImageResponsive
                source="reard-gadget--1.png"
                alt="ALLTOP ASIA Reward"
                fadeIn
              />
            </div>

            <div>
              <ImageResponsive
                source="reard-gadget--2.png"
                alt="ALLTOP ASIA Reward"
                fadeIn
              />
            </div>

            <div>
              <ImageResponsive
                source="reard-gadget--3.png"
                alt="ALLTOP ASIA Reward"
                fadeIn
              />
            </div>

            <div>
              <ImageResponsive
                source="reard-gadget--4.png"
                alt="ALLTOP ASIA Reward"
                fadeIn
              />
            </div>
          </ItemSlider>
        </Items>
      </State>
    </ImageBackground>
  );
};

export default Reward;
