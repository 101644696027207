import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const Imagestatic = (props) => {
  const { alt, className, source } = props;

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(webpQuality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}

      render={(data) => {
        const image = data.images.edges.find((n) => n.node.relativePath.includes(source));

        if (!image) {
          return null;
        }

        return (
          <Img
            alt={alt}
            className={className}
            fluid={image.node.childImageSharp.fluid}
          />
        );
      }}
    />
  );
};

Imagestatic.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
};

Imagestatic.defaultProps = {
  className: null,
};

export default Imagestatic;
