import React from 'react';
import PropTypes from 'prop-types';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import OgImage from '../images/devices/device-og--image.jpg';

function Meta({
  description,
  meta,
  title,
  image,
  pathname,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaOgImage = image || OgImage;

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Helmet
          htmlAttributes={{
            lang: currentLocale,
          }}
          title={title}
          titleTemplate={`%s - ${description}`}
          meta={[
            {
              name: 'description',
              content: metaDescription,
            },
            {
              property: 'og:title',
              content: title,
            },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:image',
              content: metaOgImage,
            },
            {
              property: 'og:url',
              content: `https://alltopasia.com${pathname}`,
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:creator',
              content: site.siteMetadata.author,
            },
            {
              name: 'twitter:title',
              content: title,
            },
            {
              name: 'twitter:description',
              content: metaDescription,
            },
          ].concat(meta)}
        />
      )}
    </IntlContextConsumer>
  );
}

Meta.defaultProps = {
  meta: [],
  description: '',
  image: '',
};

Meta.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

export default Meta;
