import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const State = styled(BackgroundImage)`
  width: 100%;
  background-position: 50% 50%;
  background-repeat: none;
  background-size: cover;
  position: relative;
`;

export default State;
