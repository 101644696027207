import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailDescContentLink,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Claim,
  ClaimButton,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--004.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'เหนื่อยขนาดนี้ คิดรึเปล่าชีวิตต้องดีขึ้น?'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'เหนื่อยขนาดนี้ คิดรึเปล่าชีวิตต้องดีขึ้น?'
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'ALLTOP Affiliate เครือข่ายตัวแทนหุ้นลม ALLTOP ASIA'
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'ALLTOP Affiliate เครือข่ายตัวแทนหุ้นลม ALLTOP ASIA'
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' && (
                  <>
                    <h2>
                      เข้าร่วมเป็นครอบครัวกับเรา ได้ง่ายๆที่:{' '}
                      <DetailDescContentLink
                        href="https://www.alltopasia.com/affiliate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ALLTOP AFFILIATE
                      </DetailDescContentLink>
                    </h2>

                    <h3>ด้วยข้อเสนอสุดพิเศษกว่าใคร! ผลประโยชน์ 4 เด้ง</h3>

                    <p>
                      เด้งที่ 1 : รับส่วนแบ่งกำไรสูงสุด 50% <br />
                      เด้งที่ 2 : เงินประจำตำแหน่ง <br />
                      เด้งที่ 3 : สมาชิกภายใต้ฝากครั้งแรก 300 บาท รับทันที 100
                      บาท <br />
                      เด้งที่ 4 : รับส่วนแบ่งจากเครือข่ายพันธมิตร 5% <br />
                      <br />
                      ส่วนแบ่งรายได้สูง และเลื่อนขั้นง่ายที่สุดในตลาด
                    </p>
                  </>
                )
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' && (
                  <>
                    <h2>
                      เข้าร่วมเป็นครอบครัวกับเรา ได้ง่ายๆที่:{' '}
                      <DetailDescContentLink
                        href="https://www.alltopasia.com/affiliate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ALLTOP AFFILIATE
                      </DetailDescContentLink>
                    </h2>

                    <h3>ด้วยข้อเสนอสุดพิเศษกว่าใคร! ผลประโยชน์ 4 เด้ง</h3>

                    <p>
                      เด้งที่ 1 : รับส่วนแบ่งกำไรสูงสุด 50% <br />
                      เด้งที่ 2 : เงินประจำตำแหน่ง <br />
                      เด้งที่ 3 : สมาชิกภายใต้ฝากครั้งแรก 300 บาท รับทันที 100
                      บาท <br />
                      เด้งที่ 4 : รับส่วนแบ่งจากเครือข่ายพันธมิตร 5% <br />
                      <br />
                      ส่วนแบ่งรายได้สูง และเลื่อนขั้นง่ายที่สุดในตลาด
                    </p>
                  </>
                )
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'ส่วนแบ่งรายได้สูง และเลื่อนขั้นง่ายที่สุดในตลาด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'ส่วนแบ่งรายได้สูง และเลื่อนขั้นง่ายที่สุดในตลาด'
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
