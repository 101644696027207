import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import State from './styled';

const ImageBackground = (props) => {
  const {
    className,
    backgroundColor,
    children,
    source,
  } = props;

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(webpQuality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}

      render={(data) => {
        const image = data.images.edges.find((n) => n.node.relativePath.includes(source));

        if (!image) {
          return null;
        }

        return (
          <State
            className={className}
            backgroundColor={backgroundColor}
            fluid={image.node.childImageSharp.fluid}
          >
            {children}
          </State>
        );
      }}
    />
  );
};

ImageBackground.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  source: PropTypes.string.isRequired,
};

ImageBackground.defaultProps = {
  className: null,
  backgroundColor: '#000000',
};

export default ImageBackground;
