import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Blank,
  Icon,
  Title,
} from './styled';

const ButtonTheme = (props) => {
  const {
    className,
    blank,
    icon,
    title,
    to,
    type,
  } = props;

  if (blank) {
    return (
      <Blank href={blank} target="_blank" rel="noopener noreferrer" type={type}>
        {icon && <Icon icon={icon} />}

        <Title>
          {title}
        </Title>
      </Blank>
    );
  }

  return (
    <Button className={className} to={to} type={type}>
      {icon && <Icon icon={icon} />}

      <Title>
        {title}
      </Title>
    </Button>
  );
};

ButtonTheme.propTypes = {
  className: PropTypes.string,
  blank: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  type: PropTypes.string,
};

ButtonTheme.defaultProps = {
  className: null,
  blank: null,
  to: null,
  type: 'gold',
  icon: null,
};

export default ButtonTheme;
