/* eslint-disable no-use-before-define */
import styled from 'styled-components';
import { ImageResponsive, calcRem } from 'utils';

const State = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${(props) => props.theme.palette.section};
  z-index: 9;
`;

const Tabs = styled.div`
  display: flex;
  height: auto;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 41.5%;
  }

  @media (min-width: 1200px) {
    width: 41.5%;
  }
`;

const TabButton = styled.button`
  overflow: hidden;
  width: 20%;
  height: auto;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: none;

  ${(props) => !props.active
    && `
    ${TabButtonImage} {
      transform: scale(1);
      filter: grayscale(100%);
    }

    @media not all and (pointer: coarse) {
      &:hover {
        ${TabButtonImage} {
          transform: scale(1.0625);
          filter: grayscale(0);
        }
      }  
    }
  `}

  ${(props) => props.active
    && `
    ${TabButtonImage} {
      filter: grayscale(0);
    }
  `}

  ${(props) => !props.active
    && `
    background: rgba(0,0,0,0.5);
    color: rgba(255,255,255,0.5);

    @media not all and (pointer: coarse) {
      &:hover {
        ${TabButtonTitle} {
          background: ${props.theme.palette.silverPlate};
          color: ${props.theme.palette.white};
        }
      }
    }
  `}

  ${(props) => props.active
    && `
    ${TabButtonTitle} {
      background: ${props.theme.palette.goldPlate};
      color: ${props.theme.palette.black};
    }
  `}
`;

const TabButtonCover = styled.div`
  z-index: 9;
  height: auto;
  position: relative;
  overflow: hidden;

  @media (max-width: 575px) {
    height: auto;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 20vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 20vw;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: auto;
  }

  @media (min-width: 1200px) {
    height: auto;
  }
`;

const TabButtonImage = styled(ImageResponsive)`
  width: 100%;
  height: 100%;
  transition: all 0.25s ease;
`;

const TabButtonTitle = styled.span`
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${calcRem(10)} ${calcRem(8)} ${calcRem(6)} ${calcRem(8)};
  transition: all 0.25s ease;
  font-size: ${calcRem(21)};
  font-weight: 500;
`;

const Main = styled.div`
  display: flex;
  height: auto;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 60%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 60%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 40%;
  }

  @media (min-width: 1200px) {
    width: 40%;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (max-width: 575px) {
    align-items: center;
    padding: 0 ${calcRem(16)};
    text-align: center;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
  }
`;

const MainContentHead = styled.h1`
  margin: 0 0 ${calcRem(16)};
  color: ${(props) => props.theme.palette.white};
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 575px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(28)};
  }
`;

const MainContentTitle = styled.h2`
  margin: 0 0 ${calcRem(16)};
  color: ${(props) => props.theme.palette.white};
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 575px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(28)};
  }
`;

const MainContentArticle = styled.p`
  line-height: 1.25;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(21)};
  }
`;

const MainContentSlogan = styled.p`
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }
`;

const MainContentProvider = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 575px) {
    justify-content: center;
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const MainContentApp = styled(ImageResponsive)`
  width: 100%;
  height: auto;
`;

const MainContentBlank = styled.a`
  display: block;

  @media (max-width: 575px) {
    width: ${calcRem(90)};
    height: auto;
    margin-bottom: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(40)};
    height: auto;
    margin-bottom: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(50)};
    height: auto;
    margin-bottom: ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(60)};
    height: auto;
    margin-bottom: ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(60)};
    height: auto;
    margin: 0 0 ${calcRem(16)};
  }

  &:first-child {
    margin-left:  ${calcRem(8)};
    margin-right:  ${calcRem(8)};
  }

  &:not(:first-child):not(:last-child) {
    margin-left:  ${calcRem(8)};
    margin-right:  ${calcRem(8)};
  }

  &:last-child {
    margin-left:  ${calcRem(8)};
    margin-right:  0;
  }
`;

const Aside = styled.div`
  height: auto;
  position: relative;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 40%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 40%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 18.5%;
  }

  @media (min-width: 1200px) {
    width: 18.5%;
  }
`;

const AsideCover = styled.div`
  @media (max-width: 575px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${calcRem(16)};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${calcRem(16)};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const AsideCoverImage = styled(ImageResponsive)`
  width: 100%;
  height: 100%;
`;

export {
  State,
  Aside,
  AsideCover,
  AsideCoverImage,
  Main,
  MainContent,
  MainContentApp,
  MainContentArticle,
  MainContentBlank,
  MainContentHead,
  MainContentProvider,
  MainContentSlogan,
  MainContentTitle,
  TabButton,
  TabButtonCover,
  TabButtonImage,
  TabButtonTitle,
  Tabs,
};
