import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import {
  State,
  Aside,
  AsideCover,
  AsideCoverImage,
  Main,
  MainContent,
  MainContentApp,
  MainContentArticle,
  MainContentBlank,
  MainContentHead,
  MainContentProvider,
  MainContentSlogan,
  MainContentTitle,
  TabButton,
  TabButtonCover,
  TabButtonImage,
  TabButtonTitle,
  Tabs,
} from './styled';

const Catagories = () => {
  const [about, setAbout] = useState(true);
  const [casno, setCasino] = useState(false);
  const [esport, setEsport] = useState(false);
  const [slot, setSlot] = useState(false);
  const [sport, setSport] = useState(false);
  const intl = useIntl();

  const handleAboutButton = () => {
    setAbout(true);
    setCasino(false);
    setSlot(false);
    setSport(false);
    setEsport(false);
  };

  const handleCasinoButton = () => {
    setAbout(false);
    setCasino(true);
    setSlot(false);
    setSport(false);
    setEsport(false);
  };

  const handleSlotButton = () => {
    setAbout(false);
    setCasino(false);
    setSlot(true);
    setSport(false);
    setEsport(false);
  };

  const handleSportButton = () => {
    setAbout(false);
    setCasino(false);
    setSlot(false);
    setSport(true);
    setEsport(false);
  };

  const handleEsportButton = () => {
    setAbout(false);
    setCasino(false);
    setSlot(false);
    setSport(false);
    setEsport(true);
  };

  return (
    <State>
      <Tabs>
        <TabButton onClick={handleAboutButton} type="button" active={about}>
          <TabButtonCover>
            <TabButtonImage
              source="catagorie-tab--about.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </TabButtonCover>

          <TabButtonTitle>
            {intl.formatMessage({ id: 'screen.catagories.tabs.about' })}
          </TabButtonTitle>
        </TabButton>

        <TabButton onClick={handleSlotButton} type="button" active={slot}>
          <TabButtonCover>
            <TabButtonImage
              source="catagorie-tab--slot.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </TabButtonCover>

          <TabButtonTitle>
            {intl.formatMessage({ id: 'screen.catagories.tabs.slots' })}
          </TabButtonTitle>
        </TabButton>

        <TabButton onClick={handleCasinoButton} type="button" active={casno}>
          <TabButtonCover>
            <TabButtonImage
              source="catagorie-tab--casino.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </TabButtonCover>

          <TabButtonTitle>
            {intl.formatMessage({ id: 'screen.catagories.tabs.casinos' })}
          </TabButtonTitle>
        </TabButton>

        <TabButton onClick={handleSportButton} type="button" active={sport}>
          <TabButtonCover>
            <TabButtonImage
              source="catagorie-tab--sport.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </TabButtonCover>

          <TabButtonTitle>
            {intl.formatMessage({ id: 'screen.catagories.tabs.sports' })}
          </TabButtonTitle>
        </TabButton>

        <TabButton onClick={handleEsportButton} type="button" active={esport}>
          <TabButtonCover>
            <TabButtonImage
              source="catagorie-tab--esport.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </TabButtonCover>

          <TabButtonTitle>
            {intl.formatMessage({ id: 'screen.catagories.tabs.esports' })}
          </TabButtonTitle>
        </TabButton>
      </Tabs>

      <Main>
        {about && (
          <MainContent>
            <MainContentHead>
              {intl.formatMessage({ id: 'screen.catagories.about.title' })}
            </MainContentHead>

            <MainContentArticle>
              {intl.formatMessage({ id: 'screen.catagories.about.desc' })}
            </MainContentArticle>

            <MainContentSlogan>
              &quot;
              {intl.formatMessage({ id: 'screen.catagories.about.slogan' })}
              &quot;
            </MainContentSlogan>
          </MainContent>
        )}

        {slot && (
          <MainContent>
            <MainContentTitle>
              {intl.formatMessage({ id: 'screen.catagories.slots.title' })}
            </MainContentTitle>

            <MainContentArticle>
              {intl.formatMessage({ id: 'screen.catagories.slots.desc' })}
            </MainContentArticle>

            <MainContentProvider>
              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--918kiss.png"
                  alt="ALLTOP ASIA 918Kiss"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--joker.png"
                  alt="ALLTOP ASIA Joker Gaming"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--mega888.png"
                  alt="ALLTOP ASIA Mega888"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--pussy888.png"
                  alt="ALLTOP ASIA Pussy888"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--xe88.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--slotciti.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--playngo.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--sampleplay.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-slot--slotxo.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="provider-casino--3win8.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="provider-casino--gamingsoft.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="provider-casino--pramaticplay.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="cq9-gaming_480.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="harbanero.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="microgaming_480.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="pgpocketsoft_480.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="spadegaming.png"
                  alt="ALLTOP ASIA XE88"
                  fadeIn
                />
              </MainContentBlank>
            </MainContentProvider>
          </MainContent>
        )}

        {casno && (
          <MainContent>
            <MainContentTitle>
              {intl.formatMessage({ id: 'screen.catagories.casinos.title' })}
            </MainContentTitle>

            <MainContentArticle>
              {intl.formatMessage({ id: 'screen.catagories.casinos.desc' })}
            </MainContentArticle>

            <MainContentProvider>
              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-casino--evolutiongaming.png"
                  alt="ALLTOP ASIA Evolution Gaming"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-casino--gclubcasino.png"
                  alt="ALLTOP ASIA G Club Casino"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-casino--sagaming.png"
                  alt="ALLTOP ASIA SA Gaming"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-casino--sexybarcarat.png"
                  alt="ALLTOP ASIA Sexy Barcarat"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-casino--wmcasino.png"
                  alt="ALLTOP ASIA WM Casino"
                  fadeIn
                />
              </MainContentBlank>
            </MainContentProvider>
          </MainContent>
        )}

        {sport && (
          <MainContent>
            <MainContentTitle>
              {intl.formatMessage({ id: 'screen.catagories.sports.title' })}
            </MainContentTitle>

            <MainContentArticle>
              {intl.formatMessage({ id: 'screen.catagories.sports.desc' })}
            </MainContentArticle>

            <MainContentProvider>
              {/* <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-sport--casa98.png"
                  alt="ALLTOP ASIA Provider"
                  fadeIn
                />
              </MainContentBlank> */}

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-sport--nova88.png"
                  alt="ALLTOP ASIA Provider"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-sport--sbobet.png"
                  alt="ALLTOP ASIA Sbobet"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-sport--ts911.png"
                  alt="ALLTOP ASIA TS911"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-sport--ufabet.png"
                  alt="ALLTOP ASIA UFA Bet"
                  fadeIn
                />
              </MainContentBlank>

              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="provider-sport--m8bet.png"
                  alt="ALLTOP ASIA UFA Bet"
                  fadeIn
                />
              </MainContentBlank>
            </MainContentProvider>
          </MainContent>
        )}

        {esport && (
          <MainContent>
            <MainContentTitle>
              {intl.formatMessage({ id: 'screen.catagories.esports.title' })}
            </MainContentTitle>

            <MainContentArticle>
              {intl.formatMessage({ id: 'screen.catagories.esports.desc' })}
            </MainContentArticle>

            <MainContentProvider>
              <MainContentBlank
                href="https://app.alltopasia.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MainContentApp
                  source="app-esport--ez.png"
                  alt="ALLTOP ASIA EZ Game"
                  fadeIn
                />
              </MainContentBlank>
            </MainContentProvider>
          </MainContent>
        )}
      </Main>

      <Aside>
        {about && (
          <AsideCover>
            <AsideCoverImage
              source="catagorie-cover--about.png"
              alt="ALLTOP Promotions"
              fadeIn
            />
          </AsideCover>
        )}

        {slot && (
          <AsideCover>
            <AsideCoverImage
              source="catagorie-cover--slot.png"
              alt="ALLTOP Promotions"
              fadeIn
            />
          </AsideCover>
        )}

        {casno && (
          <AsideCover>
            <AsideCoverImage
              source="catagorie-cover--casino.png"
              alt="ALLTOP Promotions"
              fadeIn
            />
          </AsideCover>
        )}

        {sport && (
          <AsideCover>
            <AsideCoverImage
              source="catagorie-cover--sport.png"
              alt="ALLTOP Promotions"
              fadeIn
            />
          </AsideCover>
        )}

        {esport && (
          <AsideCover>
            <AsideCoverImage
              source="catagorie-cover--esport.png"
              alt="ALLTOP Promotions"
              fadeIn
            />
          </AsideCover>
        )}
      </Aside>
    </State>
  );
};

export default Catagories;
