import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const PromotionDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="banner-alltopasia-promotion-cover--slot-50%-welcome-bonus.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>Slot 50% Welcome Bonus</HeadiingTitle>

        <HeadiingSubtitle>
          &quot; Slots & Games 50% Welcome Bonus สูงสุด 500 บาท &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            ฝากขั้นต่ำ 300 บาท รับโบนัสทันที 50% สูงสุด 500 บาท
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            Joker, 3win8, Gaming Soft, Slotciti, Pragmatic Play, CQ9, Habanero,
            Microgaming, Spadegaming ,PGSOFT
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            ยอดฝาก + โบนัสที่ได้รับ x (12) =
            ยอดเทิร์นโอเวอร์ที่ต้องทำจึงจะถอนได้
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>โปรโมชั่นนี้สำหรับสมาชิกใหม่เท่านั้น</TermList>
          <TermList>
            โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
          </TermList>
          <TermList>สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง</TermList>
          <TermList>
            สมาชิกที่รับโปรโมชั่นนี้ไม่สามารถเดิมพันคาสิโนสดและเกมส์คาสิโนดิจิตอลหรือเกมส์ที่มีการเดิมพัน
            2ฝั่งได้ หากมีการเดิมพัน ALLTOP ASIA จะไม่ให้ทำการถอนทุกกรณี
          </TermList>
          <TermList>
            การเดิมพันสล็อตต้องทำยอดกำไร 4 เท่า และ การเดิมพันเกมส์ยิงปลา 6 เท่า
            จึงจะสามารถถอนได้{' '}
          </TermList>
          <TermList>
            การทำยอดกำไรจะนับจากยอดเดิมพันสล็อต,เกมส์และคาสิโนเท่านั้น
            หากได้รับโบนัส,อั่งเปา(ซองแดง)
            หรือยอดที่ไม่ได้มาจากการเดิมพันจะไม่ถูกนำไปคิดยอดการทำกำไรทุกกรณี
          </TermList>
          <TermList>
            หากได้รับ อั่งเปา (ซองแดง)
            สมาชิกสามารถทำรายการถอนได้เลยโดยไม่ต้องทำยอดกำไรใดๆ ทั้งสิ้น
          </TermList>
          <TermList>
            ในกรณีที่ได้รับแจ็คพ็อตแต่ยอดกำไรยังไม่ถึงตามที่กำหนดสมาชิกจะต้องทำยอดกำไรให้ถึงตามที่กำหนด
            จึงจะสามารถทำรายการถอนได้
          </TermList>
          <TermList>โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้</TermList>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ ALLTOP ASIA
            เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
            รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
          </TermList>
          <TermList>
            หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์
            สามารถเลือกไม่รับโปรโมชั่นได้
          </TermList>
          <TermList>
            หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
            ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
          </TermList>
          <TermList>
            กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ ALLTOP
            ASIA ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
          </TermList>
          <TermList>
            ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
            ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>
          <TermList>
            ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
            บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
            แอดเดรสเดียวเท่านั้น
          </TermList>
          <TermList>
            หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
            สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
            จึงจะสามารถทำรายการถอนได้
          </TermList>
          <TermList>การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด</TermList>
        </TermDesc>
      </Term>

      {/* <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' ? 'หมายเหตุ: ' : 'Note: '
            }
          </IntlContextConsumer>
          สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น
        </NoteContent>
      </Note> */}

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionDetail;
