import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--010.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          ชวนเพื่อนย้ายค่ายใหม่ รับไปเลยสูงสุด 300 บาท
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot; ชวนเพื่อน ย้ายค่ายใหม่ รับค่าแนะนาสูงสุด 300 บาท ต่อคน &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'สมาชิกใหม่ที่ได้รับการแนะนำมาทำการฝาก 2,000 บาท รับเลย 300 บาท'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'สมาชิกใหม่ที่ได้รับการแนะนำมาทำการฝาก 2,000 บาท รับเลย 300 บาท'
              }
            </IntlContextConsumer>
          </DetailDescContent>

          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'สมาชิกใหม่ที่ได้รับการแนะนำมาทำการฝาก 1,000 บาท รับเลย 100 บาท'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'สมาชิกใหม่ที่ได้รับการแนะนำมาทำการฝาก 1,000 บาท รับเลย 100 บาท'
              }
            </IntlContextConsumer>
          </DetailDescContent>

          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'ปลอบใจสำหรับสมาชิกใหม่ฝากไม่ถึง 1,000 บาท รับเลย 50 บาท'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'ปลอบใจสำหรับสมาชิกใหม่ฝากไม่ถึง 1,000 บาท รับเลย 50 บาท'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' && 'ทุกเว็บในเครือ'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' && 'ทุกเว็บในเครือ'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'โบนัสที่ได้รับ x 10 = ยอดเทิร์นโอเวอร์ที่ต้องทำจึงจะถอนได้'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'โบนัสที่ได้รับ x 10 = ยอดเทิร์นโอเวอร์ที่ต้องทำจึงจะถอนได้'
              }
            </IntlContextConsumer>
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>
            ทุกยอดฝาก 1,000 บาท ขึ้นไป จะได้รับ 1 สิทธิ์แนะนำเพื่อนสูงสุด 300
            บาท/ท่าน
          </TermList>
          <TermList>
            หากต้องการรับโปรโมชั่นแนะนำเพื่อนครั้งต่อไป
            ต้องทำตามเงื่อนไขใหม่อีกครั้ง
          </TermList>
          <TermList>
            โบนัสจะถูกเติมให้สมาชิกหลังผ่านการตรวจสอบจากฝ่ายบริการลูกค้าแล้ว
          </TermList>
          <TermList>
            เพื่อนที่คุณแนะนำมาจะต้องสมัคร และฝากเงินขั้นต่ำของเว็บภายใน 24
            ชั่วโมงเท่านั้น
          </TermList>
          <TermList>สามารถขอรับได้ 1 นามสกุล ต่อ 1 สิทธิ์</TermList>
          <TermList>
            โปรโมชั่นนี้จะต้องทำเทิร์นโอเวอร์ 10 เท่า จึงจะทำการถอนได้
          </TermList>
          <TermList>
            ในส่วนของ 918Kiss, Mega888, XE88,
            Pussy888 การเดิมพันสล็อตต้องทำยอดกำไร 5 เท่า
            การเดิมพันเกมส์และคาสิโนต้องทำยอดกำไร 10 เท่า (รวมทุน)
            และไม่สามารถเดิมพันคาสิโนสดได้
            หากมีการเดิมพันคาสิโนสดจะทำการยึดโบนัสคืนทันที
          </TermList>
          <TermList>
            สมาชิกจะต้องทำการแจ้งกับทางฝ่ายบริการลูกค้า ALLTOP ASIA ทาง Line@
            ภายใน 24 ชั่วโมง
          </TermList>
          <TermList>โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้</TermList>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ ALLTOP ASIA
            เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี รางวัล
            หรือโบนัสทั้งหมดจะถูกริบคืนทันที
          </TermList>
          <TermList>
            หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
            ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
          </TermList>
          <TermList>
            กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ ALLTOP
            ASIA ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
          </TermList>
          <TermList>
            ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
            ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>
          <TermList>
            ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
            บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
            แอดเดรสเดียวเท่านั้น
          </TermList>
          <TermList>
            หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
            สมาชิกต้องมีการเดิมพันหรือยอดเทริน 1 เท่าของยอดฝาก
            จึงจะสามารถทำรายการถอนได้
          </TermList>
          <TermList>การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด</TermList>
          <TermList>
            ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 01/03/2020 เป็นต้นไป
          </TermList>
        </TermDesc>
      </Term>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'สมาชิกที่เป็นผู้แนะนำจะต้องแจ้งความประสงค์ที่จะรับโบนัสเข้าเว็บใดเว็บหนึ่งแก่ฝ่ายบริการลูกค้า'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'สมาชิกที่เป็นผู้แนะนำจะต้องแจ้งความประสงค์ที่จะรับโบนัสเข้าเว็บใดเว็บหนึ่งแก่ฝ่ายบริการลูกค้า'
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
