import styled, { css } from 'styled-components';
import { calcRem } from 'utils';
import Img from 'gatsby-image';

const State = styled.div`
  width: 100%;
  position: relative;

  ${(props) => props.size === 'portrait' && css`
    width: 100%;
    padding: 0 0 135% 0;
  `};

  ${(props) => props.size === 'landscape' && css`
    width: 100%;
    padding: 0 0 67% 0;
  `};

  ${(props) => props.size === 'banner' && css`
    width: 100%;
    padding: 0 0 37.5% 0;
  `};

  ${(props) => props.size === 'full' && css`
    width: 100%;
    height: 100%;
  `};
`;

const Position = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;

  &::after {
    ${(props) => props.overlay && css`
      content: "";
      background-color: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    `};
  };
`;

export { State, Position, Image };
