import styled, { css } from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: #ffffff;
  color: #ffffff;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
`;

const Header = styled.div`
  width: 100%;
  height: ${calcRem(90)};
  position: relative;
  background-color: #020922;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const HeaderState = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${calcRem(90)};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const HeaderBrand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: ${calcRem(196)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(196)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(196)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(196)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(196)};
  }
`;

const HeaderNav = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const HeaderRequest = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const HeaderRequestButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calcRem(129)};
  height: ${calcRem(42)};
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }

  ${(props) => props.register && css`
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: ${calcRem(4)};

    @media (max-width: 575px) {
      margin: 0 ${calcRem(8)} 0 0;
    }
  
    @media (min-width: 576px) and (max-width: 767px) {
      margin: 0 ${calcRem(8)} 0 0;
    }
  
    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 ${calcRem(8)} 0 0;
    }
  
    @media (min-width: 992px) and (max-width: 1199px) {
      margin: 0 ${calcRem(8)} 0 0;
    }
  
    @media (min-width: 1200px) {
      margin: 0 ${calcRem(8)} 0 0;
    }

    &:hover {
      color: #ffffff;
    }
  `}

  ${(props) => props.signin && css`
    color: #000000;

    @media (max-width: 575px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 576px) and (max-width: 767px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 1200px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    &:hover {
      color: #000000;
    }
  `}
`;

const HeaderRequestButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calcRem(129)};
  height: ${calcRem(42)};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const Hilight = styled.div`
  width: 100%;
  background-color: #01162c;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const HilightVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }
`;

const HilightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }
`;

const HilightContentArticle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
  }
`;

const HilightContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const HilightContentHeading = styled.h1`
  margin: 0;
  line-height: 1.25;
  font-weight: 400;

  @media (max-width: 575px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const HilightContentText = styled.p`
  line-height: 1.25;
  margin: 0;

  @media (max-width: 575px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const HilightContentItalic = styled.span`
  font-style: italic;
  font-weight: 500;
`;

const HilightContentUnderline = styled.span`
  color: #d9b449;
  font-style: italic;
  font-weight: 700;
  text-decoration: underline;
`;

const HilightContentBrand = styled.div`
  @media (max-width: 575px) {
    width: ${calcRem(170)};
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(250)};
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(150)};
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(165)};
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(250)};
    margin: 0 ${calcRem(16)};
  }
`;

const HilightContentSlogan = styled.h4`
  margin: ${calcRem(32)} 0 0;
  font-style: italic;
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(48)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
  }
`;


const Info = styled.div`
  width: 100%;
  background-color: #ffffff;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const InfoVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }
`;

const InfoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }
`;

const InfoContentImage = styled.div`
  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 70%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 70%;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }
`;

const Provider = styled.div`
  width: 100%;
  background-color: #01162c;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const ProviderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }
`;

const ProviderContentArticle = styled.div`
  line-height: 1.25;

  @media (max-width: 575px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const ProviderContentTitle = styled.h2`
  margin: 0 0 ${calcRem(16)};
  line-height: 1.25;
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(42)};
  }
`;

const ProviderContentDesc = styled.p`
  margin: 0;
  font-weight: 500;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ProviderContentDescHilight = styled.span`
  display: block;
  color: #d9b449;
`;

const ProviderApp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 0 0 ${calcRem(16)};
  }
`;

const ProviderAppImage = styled.div`
  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 70%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 70%;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }
`;

const Queations = styled.div`
  width: 100%;
  background-color: #ffffff;
  color: #000000;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const QueationsLists = styled.div`
  width: 100%;
  position: relativel

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const QueationsTitle = styled.h2`
  margin: 0 0 ${calcRem(16)};
  line-height: 1.25;
  color: #d9b449;
  font-weight: 500;
  text-align: center;

  @media (max-width: 575px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(42)};
  }
`;

const QueationsInteractive = styled.div`
  position: relative;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const QueationsInteractiveRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const QueationsInteractiveTitle = styled.h3`
  margin: 0 auto 0 0;
  line-height: 2;
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const QueationsInteractiveArrow = styled.span`
  display: block;

  @media(max-width: 575px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media(min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media(min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
    margin: 0 ${calcRem(32)} 0 0;
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
    margin: 0 ${calcRem(32)} 0 0;
  }

  @media(min-width: 1200px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
    margin: 0 ${calcRem(32)} 0 0;
  }
`;

const QueationsInteractivePlus = styled.span`
  display: block;

  @media(max-width: 575px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media(min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media(min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media(min-width: 1200px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }
`;

const Howto = styled.div`
  width: 100%;
  background-color: #01162c;
`;

const HowtoInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const HowtoTitle = styled.h2`
  margin: 0 0 ${calcRem(16)};
  line-height: 1;
  font-weight: 500;
  text-align: center;

  @media (max-width: 575px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(42)};
  }
`;

const HowtoSubtitle = styled.p`
  margin: 0;
  line-height: 1;
  color: #d9b449;
  font-weight: 500;
  text-align: center;

  @media (max-width: 575px) {
    font-size: ${calcRem(42)};
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(48)};
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(36)};
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(42)};
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
    margin: 0 0 ${calcRem(64)};
  }
`;

const HowtoStep = styled.div`
  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const HowtoStepList = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media(min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media(min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media(min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }

    &:last-chile {
      margin-bottom: 0;
    }
`;

const HowtoStepListNumber = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #d9b448;

  @media(max-width: 575px) {
    width: ${calcRem(100)};
    height: ${calcRem(100)};
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media(min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(100)};
    height: ${calcRem(100)};
    margin: 0 ${calcRem(32)} 0 0;
  }

  @media(min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(100)};
    height: ${calcRem(100)};
    margin: 0 ${calcRem(32)} 0 0;
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(100)};
    height: ${calcRem(100)};
    margin: 0 ${calcRem(32)} 0 0;
  }

  @media(min-width: 1200px) {
    width: ${calcRem(100)};
    height: ${calcRem(100)};
    margin: 0 ${calcRem(32)} 0 0;
  }
`;

const HowtoStepListNumberText = styled.span`
  display: block;
  color: #333333;
  font-weight: 500;

  @media(max-width: 575px) {
    font-size: ${calcRem(60)};
  }

  @media(min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(60)};
  }

  @media(min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(60)};
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(60)};
  }

  @media(min-width: 1200px) {
    font-size: ${calcRem(60)};
  }
`;

const HowtoStepListText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: ${calcRem(16)};
  background-color: #ffffff;
  color: #000000;

  @media(max-width: 575px) {
    padding: ${calcRem(32)};
  }

  @media(min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)};
  }

  @media(min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(32)};
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(32)};
  }

  @media(min-width: 1200px) {
    padding: ${calcRem(32)};
  }
`;

const HowtoStepListTextTitle = styled.h3`
  margin: 0 0 ${calcRem(8)};
  line-height: 1;

  @media(max-width: 575px) {
    font-size: ${calcRem(42)};
  }

  @media(min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media(min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(36)};
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media(min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const HowtoStepListTextDesc = styled.p`
  margin: 0;

  @media(max-width: 575px) {
    font-size: ${calcRem(28)};
  }

  @media(min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(24)};
  }

  @media(min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media(min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(24)};
  }

  @media(min-width: 1200px) {
    font-size: ${calcRem(24)};
  }
`;

const HowtoRegister = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media(max-width: 575px) {
  }

  @media(min-width: 576px) and (max-width: 767px) {
  }

  @media(min-width: 768px) and (max-width: 991px) {
  }

  @media(min-width: 992px) and (max-width: 1199px) {
  }

  @media(min-width: 1200px) {
  }
`;

const HowtoRegisterTitle = styled.span`
  display: block;
  margin 0 ${calcRem(16)} 0 0;
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(28)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(28)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(28)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(28)};
  }
`;

const HowtoRegisterButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calcRem(129)};
  height: ${calcRem(42)};
  font-weight: 500;
  color: #000000;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }

  &:hover {
    color: #000000;
  }
`;

const HowtoRegisterButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calcRem(129)};
  height: ${calcRem(42)};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const SeriesMuy = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: #030f39;
  overflow: hidden;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(128)} 0;
  }
`;

const SeriesMuyVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }
`;

const SeriesMuyCaption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 ${calcRem(16)} 0 0;
  }
`;

const SeriesMuyCaptionName = styled.span`
  display: block;
  font-weight: 700;
  font-style: italic;
  line-height: 1;

  @media (max-width: 575px) {
    font-size: ${calcRem(80)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(80)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(72)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(72)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(90)};
  }
`;

const SeriesMuyCaptionTitle = styled.h3`
  font-weight: 700;
  font-style: italic;
  line-height: 1.5;

  @media (max-width: 575px) {
    font-size: ${calcRem(40)};
    margin: 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(40)};
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(34)};
    margin: 0 0 ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
    margin: 0 0 ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
    margin: 0 0 ${calcRem(48)};
  }
`;

const SeriesMuyCaptionSubtitle = styled.p`
  margin: 0;
  font-weight: 700;
  font-style: italic;
  line-height: 1.5;

  @media (max-width: 575px) {
    font-size: ${calcRem(40)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(40)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(34)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
  }
`;

const SeriesMuyImage = styled.div`
  @media (max-width: 575px) {
    width: 35%;
    margin: 0 auto;
    position: relative;
    bottom: ${calcRem(-32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 35%;
    margin: 0 auto;
    position: relative;
    bottom: ${calcRem(-32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 17.5%;
    position: absolute;
    right: ${calcRem(32)};
    bottom: 0;
  }
  

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 17.5%;
    position: absolute;
    right: ${calcRem(32)};
    bottom: 0;
  }

  @media (min-width: 1200px) {
    width: 17.5%;
    position: absolute;
    right: ${calcRem(32)};
    bottom: 0;
  }
`;

const SeriesMew = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: #ffffff;
  color: #000000;
  overflow: hidden;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(128)} 0;
  }
`;

const SeriesMewVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 575px) {
    order: 1;
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    order: 1;
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 0 0 auto;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 0 0 auto;
  }

  @media (min-width: 1200px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 0 0 auto;
  }
`;

const SeriesMewCaption = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: relative;

@media (max-width: 575px) {
  order: 2;
  align-items: center;
  width: 100%;
  margin: 0 0 ${calcRem(32)};
  text-align: center;
}

@media (min-width: 576px) and (max-width: 767px) {
  order: 2;
  align-items: center;
  width: 100%;
  margin: 0 0 ${calcRem(32)};
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  align-items: flex-start;
  width: 50% ;
  margin: 0 auto 0 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  align-items: flex-start;
  width: 50% ;
  margin: 0 auto 0 0;
}

@media (min-width: 1200px) {
  align-items: flex-start;
  width: 50% ;
  margin: 0 auto 0 0;
}
`;

const SeriesMewCaptionName = styled.span`
  display: block;
  font-weight: 700;
  font-style: italic;
  line-height: 1;

  @media (max-width: 575px) {
    font-size: ${calcRem(80)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(80)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(72)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(72)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(90)};
  }
`;

const SeriesMewCaptionTitle = styled.h3`
  font-weight: 700;
  font-style: italic;
  line-height: 1.5;

  @media (max-width: 575px) {
    font-size: ${calcRem(40)};
    margin: 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(40)};
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(34)};
    padding: 0 0 0 ${calcRem(135)};
    margin: 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
    padding: 0 0 0 ${calcRem(135)};
    margin: 0;
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
    padding: 0 0 0 ${calcRem(176)};
    margin: 0;
  }
`;

const SeriesMewCaptionSubtitle = styled.p`
margin: 0;
font-weight: 700;
font-style: italic;
line-height: 1.5;

@media (max-width: 575px) {
  font-size: ${calcRem(40)};
}

@media (min-width: 576px) and (max-width: 767px) {
  font-size: ${calcRem(40)};
}

@media (min-width: 768px) and (max-width: 991px) {
  font-size: ${calcRem(34)};
}

@media (min-width: 992px) and (max-width: 1199px) {
  font-size: ${calcRem(36)};
}

@media (min-width: 1200px) {
  font-size: ${calcRem(48)};
}
`;

const SeriesMewImage = styled.div`
  @media (max-width: 575px) {
    order: 3;
    width: 50%;
    margin: 0 auto;
    position: relative;
    bottom: ${calcRem(-32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    order: 3;
    width: 50%;
    margin: 0 auto;
    position: relative;
    bottom: ${calcRem(-32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 32.5%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-35%, 0);
  }
  

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 34%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-40%, 0);
  }

  @media (min-width: 1200px) {
    width: 34%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-40%, 0);
  }
`;


const SeriesNut = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: #005677;
  color: #ffffff;
  overflow: hidden;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(128)} 0;
  }
`;

const SeriesNutVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 99;

  @media (max-width: 575px) {
    order: 1;
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    order: 1;
    width: 100%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 auto 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 auto 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(41.25% - ${calcRem(16)});
    margin: 0 auto 0 0;
  }
`;

const SeriesNutCaption = styled.div`
display: flex;
flex-direction: column;
position: relative;

@media (max-width: 575px) {
  order: 2;
  align-items: center;
  width: 100%;
  margin: 0 0 ${calcRem(32)};
  text-align: center;
}

@media (min-width: 576px) and (max-width: 767px) {
  order: 2;
  align-items: center;
  width: 100%;
  margin: 0 0 ${calcRem(32)};
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  align-items: flex-start;
  width: 50% ;
  text-align: right;
}

@media (min-width: 992px) and (max-width: 1199px) {
  align-items: flex-start;
  width: 50% ;
  text-align: right;
}

@media (min-width: 1200px) {
  align-items: flex-start;
  width: 50% ;
  text-align: right;
}
`;

const SeriesNutCaptionName = styled.span`
  display: block;
  font-weight: 700;
  font-style: italic;
  line-height: 1;

  @media (max-width: 575px) {
    font-size: ${calcRem(80)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(80)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(72)};
    margin: 0 ${calcRem(48)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(72)};
    margin: 0 ${calcRem(48)} 0 0;
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(90)};
    margin: 0 ${calcRem(48)} 0 0;
  }
`;

const SeriesNutCaptionTitle = styled.h3`
  font-weight: 700;
  font-style: italic;
  line-height: 1.5;

  @media (max-width: 575px) {
    font-size: ${calcRem(40)};
    margin: 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(40)};
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(34)};
    margin: 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
    margin: 0;
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
    margin: 0;
  }
`;

const SeriesNutCaptionTitleLine = styled.span`
  display: block;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    ${({ line2 }) => line2 && `
      transform: translate(55%,0);
    `}
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    ${({ line2 }) => line2 && `
      transform: translate(55%,0);
    `}
  }

  @media (min-width: 1200px) {
    ${({ line2 }) => line2 && `
      transform: translate(60%,0);
    `}
  }
`;

const SeriesNutCaptionSubtitle = styled.p`
  margin: 0;
  font-weight: 700;
  font-style: italic;
  line-height: 1.5;

  @media (max-width: 575px) {
    font-size: ${calcRem(40)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(40)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(34)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
  }
`;


const SeriesNutCaptionRow = styled.div`
  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  @media (min-width: 1200px) {
    display: flex;
    position: relative;
    align-items: flex-end;
  }
`;

const SeriesNutImage = styled.div`
  @media (max-width: 575px) {
    order: 3;
    width: 50%;
    margin: 0 auto;
    position: relative;
    bottom: ${calcRem(-32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    order: 3;
    width: 50%;
    margin: 0 auto;
    position: relative;
    bottom: ${calcRem(-32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 32%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-37.5%, 0);
    z-index: 9;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 32%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-37.5%, 0);
    z-index: 9;
  }

  @media (min-width: 1200px) {
    width: 37%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-37.5%, 0);
    z-index: 9;
  }
`;

export {
  State,
  Video,
  Header,
  HeaderBrand,
  HeaderNav,
  HeaderRequest,
  HeaderRequestButton,
  HeaderRequestButtonInner,
  HeaderState,
  Hilight,
  HilightContent,
  HilightContentArticle,
  HilightContentBrand,
  HilightContentHeading,
  HilightContentItalic,
  HilightContentRow,
  HilightContentSlogan,
  HilightContentText,
  HilightContentUnderline,
  HilightVideo,

  Howto,
  HowtoInner,
  HowtoRegister,
  HowtoRegisterButton,
  HowtoRegisterButtonInner,
  HowtoRegisterTitle,
  HowtoStep,
  HowtoStepList,
  HowtoStepListNumber,
  HowtoStepListNumberText,
  HowtoStepListText,
  HowtoStepListTextDesc,
  HowtoStepListTextTitle,
  HowtoSubtitle,
  HowtoTitle,
  Info,
  InfoContent,
  InfoContentImage,
  InfoVideo,
  Provider,
  ProviderApp,
  ProviderAppImage,
  ProviderContent,
  ProviderContentArticle,
  ProviderContentDesc,
  ProviderContentDescHilight,
  ProviderContentTitle,
  Queations,
  QueationsInteractive,
  QueationsInteractiveArrow,
  QueationsInteractivePlus,
  QueationsInteractiveRow,
  QueationsInteractiveTitle,
  QueationsLists,
  QueationsTitle,




  SeriesMuy,
  SeriesMuyVideo,
  SeriesMuyCaption,
  SeriesMuyCaptionName,
  SeriesMuyCaptionTitle,
  SeriesMuyCaptionSubtitle,
  SeriesMuyImage,
  SeriesMew,
  SeriesMewVideo,
  SeriesMewCaption,
  SeriesMewCaptionName,
  SeriesMewCaptionTitle,
  SeriesMewCaptionSubtitle,
  SeriesMewImage,
  SeriesNut,
  SeriesNutVideo,
  SeriesNutCaption,
  SeriesNutCaptionRow,
  SeriesNutCaptionName,
  SeriesNutCaptionTitle,
  SeriesNutCaptionTitleLine,
  SeriesNutCaptionSubtitle,
  SeriesNutImage,
};
