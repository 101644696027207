import React from 'react';
import useWindowDimensions from 'hooks';
import { ButtonTheme } from 'components';
import { ImageBackground } from 'utils';
import { IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';
import {
  State,
  Hilight,
  HilightContent,
  HilightContentRed,
  HilightContentRow,
  HilightContentUnderline,
  HilightRequest,
} from './styled';

const Affiliate = () => {
  const intl = useIntl();
  const { width } = useWindowDimensions();

  if (width > 767) {
    return (
      <ImageBackground source="affiliate-background--richman.jpg">
        <State>
          <Hilight>
            <HilightContent>
              <HilightContentRow>
                {intl.formatMessage({ id: 'screen.affiliate.content.row_1' })}
              </HilightContentRow>

              <HilightContentRow>
                {intl.formatMessage({ id: 'screen.affiliate.content.row_2' })}
              </HilightContentRow>

              <HilightContentRow>
                {intl.formatMessage({ id: 'screen.affiliate.content.row_3' })}

                <IntlContextConsumer>
                  {({ language: currentLanguage }) => currentLanguage === 'en' && (<br />)}
                </IntlContextConsumer>

                <HilightContentRed>
                  &quot;
                  {intl.formatMessage({ id: 'screen.affiliate.hilight.red_1' })}
                  &quot;
                </HilightContentRed>
              </HilightContentRow>

              <HilightContentRow last>
                <HilightContentUnderline>
                  {intl.formatMessage({ id: 'screen.affiliate.hilight.red_2' })}
                </HilightContentUnderline>
                {' '}
                <HilightContentRed>
                  {intl.formatMessage({ id: 'screen.affiliate.hilight.red_3' })}
                </HilightContentRed>
              </HilightContentRow>

              <HilightContentRow>
                <HilightContentRed>
                  &quot;
                  {intl.formatMessage({ id: 'screen.affiliate.hilight.red_4' })}
                  &quot;
                </HilightContentRed>
              </HilightContentRow>
            </HilightContent>

            <HilightRequest>
              <ButtonTheme
                icon="partner"
                title={intl.formatMessage({ id: 'screen.request.affiliate' })}
                blank="/affiliate"
                type="gold"
              />
            </HilightRequest>
          </Hilight>
        </State>
      </ImageBackground>
    );
  }

  return null;
};

export default Affiliate;
