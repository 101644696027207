import styled from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  @media (max-width: 575px) {
    padding: ${calcRem(64)} ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} ${calcRem(32)};
  }
`;

const Article = styled.div`
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const ArticleCover = styled.div`
  display: block;
  width: 100%;
  height: auto;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    max-width: ${calcRem(400)};
    margin: 0 auto ${calcRem(64)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-width: ${calcRem(400)};
    margin: 0 auto ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: ${calcRem(400)};
    margin: 0 auto ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    max-width: ${calcRem(400)};
    margin: 0 auto ${calcRem(64)};
  }
`;

const ArticleTitle = styled.h1`
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 ${calcRem(16)};

  @media (max-width: 575px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const ArticleDesc = styled.p`
  margin: 0;
  line-height: 1.25;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(24)};
  }
`;

const Providers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1200px) {
    align-items: center;
    justify-content: center;
  }
`;

const ProviderApp = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: 50%;
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 ${calcRem(16)} 0 0;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 ${calcRem(32)} ${calcRem(16)} 0;

    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 ${calcRem(32)} ${calcRem(16)} 0;

    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 1200px) {
    margin: 0 ${calcRem(32)} ${calcRem(16)} 0;

    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const ProviderAppCover = styled.div`
  height: auto;
  transition: transform 0.25s ease;

  @media (max-width: 575px) {
    width: calc(100% - ${calcRem(64)});
    margin: 0 0 ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(120)};
    margin: 0 0 ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(160)};
    margin: 0 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(170)};
    margin: 0 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(170)};
    margin: 0 0 ${calcRem(16)};
  }

  @media not all and (pointer: coarse) {
    ${ProviderApp}:hover & {
      transform: translate(0, ${calcRem(-8)});
    }
  }
`;

const ProviderAppTitle = styled.h2`
  color: #c0c0c0;
  font-size: ${calcRem(18)};
  text-align: center;
  margin: 0;
  transition: color 0.25s ease;

  @media (max-width: 575px) {
    font-size: ${calcRem(28)};
    font-weight: 500;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
    font-weight: 400;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
    font-weight: 400;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
    font-weight: 400;
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
    font-weight: 400;
  }

  @media not all and (pointer: coarse) {
    ${ProviderApp}:hover & {
      color: #CC9933;
    }
  }
`;

export {
  State,
  Article,
  ArticleCover,
  ArticleTitle,
  ArticleDesc,
  Providers,
  ProviderApp,
  ProviderAppCover,
  ProviderAppTitle,
};
