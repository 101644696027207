import styled, { css } from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  height: ${calcRem(576)};
  position: relative;

  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const Hilight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)} 0 0;
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)} 0 0;
  }
`;

const HilightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const HilightContentRow = styled.span`
  display: block;
  line-height: 1.25;
  color: #ffffff;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(48)};
  }

  ${(props) => props.last && css`
    margin: 0 0 ${calcRem(16)};
  `};
`;

const HilightContentRed = styled.span`
  line-height: 0.75;
  color: #ff0000;
  font-weight: 700;
  font-style: italic;
  white-space: nowrap;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(48)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(54)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(60)};
  }
`;

const HilightContentUnderline = styled.span`
  font-style: italic;
  text-decoration: underline;
`;

const HilightRequest = styled.div`
  @media (max-width: 575px) {
    width: ${calcRem(168)};
    height: ${calcRem(52)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(136)};
    height: ${calcRem(42)};
  }
`;

export {
  State,
  Hilight,
  HilightContent,
  HilightContentRed,
  HilightContentRow,
  HilightContentUnderline,
  HilightRequest,
};
