import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { calcRem, Iconsvg } from 'utils';

const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Blank = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Iconsvg)`
  width: ${calcRem(72)};
  height: ${calcRem(72)};
  margin: 0 0 ${calcRem(16)};
  fill: ${(props) => props.theme.palette.base};

  ${Button}:hover & {
    fill: ${(props) => props.theme.palette.theme};
  }

  ${Blank}:hover & {
    fill: ${(props) => props.theme.palette.theme};
  }
`;

const Title = styled.span`
  display: block;
  transition: color 0.5s ease;
  width: 100%;
  color: ${(props) => props.theme.palette.base};
  font-size: ${calcRem(24)};
  font-weight: 400;
  text-align: center;
  white-space: nowrap;

  ${Button}:hover & {
    color: ${(props) => props.theme.palette.theme};
  }

  ${Blank}:hover & {
    color: ${(props) => props.theme.palette.theme};
  }
`;

export {
  Blank,
  Button,
  Icon,
  Title,
};
