import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  height: auto;
  background-color: #181818;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} 0;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} 0;
  }
`;

const Title = styled.h1`
  width: 100%;
  height: auto;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 575px) {
    font-size: ${calcRem(30)};
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(36)};
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
    margin: 0 0 ${calcRem(64)};
  }
`;

const Lists = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ListsPromotion = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000000;
  height: auto;

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 ${calcRem(32)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: calc(50% - ${calcRem(8)});
    margin: 0 ${calcRem(16)} ${calcRem(32)} 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - ${calcRem(16)});
    margin: 0 ${calcRem(32)} ${calcRem(32)} 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(33.333% - ${calcRem(21.333)});
    margin: 0 ${calcRem(32)} ${calcRem(32)} 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: 1200px) {
    width: calc(25% - ${calcRem(24)});
    margin: 0 ${calcRem(32)} ${calcRem(32)} 0;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
`;

const ListsPromotionCover = styled.div`
  overflow: hidden;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ListsPromotionCoverRouter = styled(Link)`
  display: block;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ListsPromotionDesc = styled.div`
  @media (max-width: 575px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(16)} ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(16)} ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(16)} ${calcRem(32)};
  }
`;

const ListsPromotionDescTitle = styled.h2`
  font-weight: 500;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(30)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }
`;

const ListsPromotionDescTitleRouter = styled(Link)`
  display: block;
  color: #cc9933;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ListsPromotionDescSubtitle = styled.p`
  margin: 0;
  line-height: 1.25;
  color: #c0c0c0;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }
`;

const ListsPromotionSpacer = styled.span`
  display: block;
  flex-grow: 1;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ListsPromotionRequest = styled.div`
  display: flex;

  @media (max-width: 575px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(16)} ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(16)} ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(16)} ${calcRem(32)};
  }
`;

const ListsPromotionRequestRouter = styled.div`
  width: calc(50% - ${calcRem(8)});
  margin: 0 0 0 ${calcRem(8)};

  @media (max-width: 575px) {
    height: ${calcRem(48)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    height: ${calcRem(36)};
  }
`;

const ListsPromotionRequestSignin = styled.div`
  width: calc(50% - ${calcRem(8)});
  margin: 0 ${calcRem(8)} 0 0;

  @media (max-width: 575px) {
    height: ${calcRem(48)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    height: ${calcRem(36)};
  }
`;

const Filters = styled.div`
  width: 100%;
  margin: 0 0 ${calcRem(64)};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const FiltersMenu = styled.ul`
  display: flex;
  weight: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 575px) {
    justify-content: space-between;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    justify-content: space-between;
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const FiltersMenuList = styled.li`
  @media (max-width: 575px) {
    margin: 0 ${calcRem(4)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 ${calcRem(4)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 ${calcRem(8)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 ${calcRem(8)};
  }

  @media (min-width: 1200px) {
    margin: 0 ${calcRem(8)};
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const FiltersMenuRouter = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: ${calcRem(4)};
  transition: border-color 0.25s ease;

  ${(props) =>
    props.active &&
    css`
      border: ${calcRem(2)} solid rgba(204, 153, 51, 1);
    `}

  @media (max-width: 575px) {
    width: ${calcRem(84)};
    height: ${calcRem(84)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(84)};
    height: ${calcRem(84)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(90)};
    height: ${calcRem(90)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(90)};
    height: ${calcRem(90)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(90)};
    height: ${calcRem(90)};
  }

  &:hover {
    border: ${calcRem(2)} solid rgba(204, 153, 51, 1);
  }
`;

const FiltersMenuIcon = styled.svg`
  fill: rgba(204, 153, 51, 0.75);
  transition: fill 0.25s ease;

  ${(props) =>
    props.active &&
    css`
      fill: rgba(204, 153, 51, 1);
    `}

  ${FiltersMenuRouter}:hover & {
    fill: rgba(204, 153, 51, 1);
  }

  @media (max-width: 575px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(48)};
    height: ${calcRem(48)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(48)};
    height: ${calcRem(48)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(48)};
    height: ${calcRem(48)};
  }
`;

const FiltersMenuText = styled.span`
  display: block;
  font-size: ${calcRem(18)};
  font-weight: 700;
  color: rgba(204, 153, 51, 0.75);
  transition: color 0.25s ease;

  ${(props) =>
    props.active &&
    css`
      color: rgba(204, 153, 51, 1);
    `}

  ${FiltersMenuRouter}:hover & {
    color: rgba(204, 153, 51, 1);
  }

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

export {
  State,
  Filters,
  FiltersMenu,
  FiltersMenuList,
  FiltersMenuRouter,
  FiltersMenuIcon,
  FiltersMenuText,
  Lists,
  ListsPromotion,
  ListsPromotionCover,
  ListsPromotionCoverRouter,
  ListsPromotionDesc,
  ListsPromotionDescSubtitle,
  ListsPromotionDescTitle,
  ListsPromotionDescTitleRouter,
  ListsPromotionRequest,
  ListsPromotionRequestRouter,
  ListsPromotionRequestSignin,
  ListsPromotionSpacer,
  Title,
};
