import React from 'react';
import { Container } from 'components';
import { ImageBackground, ImageResponsive } from 'utils';
import CoverHilight from 'images/affiliate/affiliate-video--hilight.webp';
import VideoHilight from 'videos/alltopaff-hilight--fulid.mp4';
import CoverInfo from 'images/affiliate/affiliate-video--info.webp';
import VideoInfo from 'videos/alltopaff-info--fulid.mp4';
import VideoMuy from 'videos/alltopaff-hilight--muy.mp4';
import CoverMuy from 'images/affiliate/affiliate-video--muy.jpg';
import VideoMew from 'videos/alltopaff-hilight--mew.mp4';
import CoverMew from 'images/affiliate/affiliate-video--mew.jpg';
import VideoNut from 'videos/alltopaff-hilight--nut.mp4';
import CoverNut from 'images/affiliate/affiliate-video--nut.jpg';

import {
  State,
  Video,
  Header,
  HeaderBrand,
  HeaderNav,
  HeaderRequest,
  HeaderRequestButton,
  HeaderRequestButtonInner,
  HeaderState,
  Hilight,
  HilightContent,
  HilightContentArticle,
  HilightContentBrand,
  HilightContentHeading,
  HilightContentItalic,
  HilightContentRow,
  HilightContentSlogan,
  HilightContentText,
  HilightContentUnderline,
  HilightVideo,
  Howto,
  HowtoInner,
  HowtoRegister,
  HowtoRegisterButton,
  HowtoRegisterButtonInner,
  HowtoRegisterTitle,
  HowtoStep,
  HowtoStepList,
  HowtoStepListNumber,
  HowtoStepListNumberText,
  HowtoStepListText,
  HowtoStepListTextDesc,
  HowtoStepListTextTitle,
  HowtoSubtitle,
  HowtoTitle,
  Info,
  InfoContent,
  InfoContentImage,
  InfoVideo,
  Provider,
  ProviderApp,
  ProviderAppImage,
  ProviderContent,
  ProviderContentArticle,
  ProviderContentDesc,
  ProviderContentDescHilight,
  ProviderContentTitle,
  Queations,
  QueationsInteractive,
  QueationsInteractiveArrow,
  QueationsInteractivePlus,
  QueationsInteractiveRow,
  QueationsInteractiveTitle,
  QueationsLists,
  QueationsTitle,
  SeriesMuy,
  SeriesMuyVideo,
  SeriesMuyCaption,
  SeriesMuyCaptionName,
  SeriesMuyCaptionTitle,
  SeriesMuyCaptionSubtitle,
  SeriesMuyImage,
  SeriesMew,
  SeriesMewVideo,
  SeriesMewCaption,
  SeriesMewCaptionName,
  SeriesMewCaptionTitle,
  SeriesMewCaptionSubtitle,
  SeriesMewImage,
  SeriesNut,
  SeriesNutVideo,
  SeriesNutCaption,
  SeriesNutCaptionRow,
  SeriesNutCaptionName,
  SeriesNutCaptionTitle,
  SeriesNutCaptionTitleLine,
  SeriesNutCaptionSubtitle,
  SeriesNutImage,
} from './styled';

const Allaffiliate = () => (
  <State>
    <Header>
      <Container>
        <HeaderState>
          <HeaderNav />

          <HeaderBrand>
            <ImageResponsive
              source="alltopaffiliate-brand.png"
              alt="ALLTOP AFFLIATE"
              fadeIn
            />
          </HeaderBrand>

          <HeaderRequest>
            <HeaderRequestButton href="https://dash.alltopaff.com/login" target="_blank" rel="noopener noreferrer" register>
              เข้าสู่ระบบ
            </HeaderRequestButton>

            <HeaderRequestButton href="https://dash.alltopaff.com/signup" target="_blank" rel="noopener noreferrer" signin>
              <ImageBackground fadeIn source="affiliate-button--gold.png">
                <HeaderRequestButtonInner>
                  สมัคร
                </HeaderRequestButtonInner>
              </ImageBackground>
            </HeaderRequestButton>
          </HeaderRequest>
        </HeaderState>
      </Container>
    </Header>

    <SeriesMuy>
      <Container>
        <SeriesMuyVideo>
          <Video poster={CoverMuy} controls>
            <source src={VideoMuy} type="video/mp4" />
          </Video>
        </SeriesMuyVideo>

        <SeriesMuyCaption>
          <SeriesMuyCaptionName>หมวย</SeriesMuyCaptionName>
          <SeriesMuyCaptionTitle>ความฝันที่มากมาย โลกที่กว้างใหญ่</SeriesMuyCaptionTitle>
          <SeriesMuyCaptionSubtitle>&quot;จะมีโอกาสได้เห็นมั้ย&quot;</SeriesMuyCaptionSubtitle>
        </SeriesMuyCaption>

        <SeriesMuyImage>
          <ImageResponsive
            source="alltopaffiliate-series--muy.png"
            alt="ALLTOP AFFLIATE"
            fadeIn
          />
        </SeriesMuyImage>
      </Container>
    </SeriesMuy>

    <SeriesMew>
      <Container>
        <SeriesMewCaption>
          <SeriesMewCaptionName>หมิว</SeriesMewCaptionName>
          <SeriesMewCaptionTitle>ชีวิตจะมีโอกาสมากกว่านี้มั้ย</SeriesMewCaptionTitle>
          <SeriesMewCaptionSubtitle>.........จะดีขึ้นกว่านี่ได้อีกมั้ย</SeriesMewCaptionSubtitle>
        </SeriesMewCaption>

        <SeriesMewImage>
          <ImageResponsive
            source="alltopaffiliate-series--mew.png"
            alt="ALLTOP AFFLIATE"
            fadeIn
          />
        </SeriesMewImage>

        <SeriesMewVideo>
          <Video poster={CoverMew} controls>
            <source src={VideoMew} type="video/mp4" />
          </Video>
        </SeriesMewVideo>
      </Container>
    </SeriesMew>

    <SeriesNut>
      <Container>
        <SeriesNutVideo>
          <Video poster={CoverNut} controls>
            <source src={VideoNut} type="video/mp4" />
          </Video>
        </SeriesNutVideo>

        <SeriesNutImage>
          <ImageResponsive
            source="alltopaffiliate-series--nut.png"
            alt="ALLTOP AFFLIATE"
            fadeIn
          />
        </SeriesNutImage>

        <SeriesNutCaption>
          <SeriesNutCaptionRow>
            <SeriesNutCaptionName>ณัฐ</SeriesNutCaptionName>
            <SeriesNutCaptionTitle>คนที่บ้าน &quot;สำคัญ&quot; สำหรับเขา</SeriesNutCaptionTitle>
          </SeriesNutCaptionRow>

          <SeriesNutCaptionSubtitle>
            <SeriesNutCaptionTitleLine>การอยู่ตัวคนเดียว</SeriesNutCaptionTitleLine>
            <SeriesNutCaptionTitleLine line2>
              ท่ามกลางเมืองหลวง ไม่ง่ายเลย
            </SeriesNutCaptionTitleLine>
          </SeriesNutCaptionSubtitle>
        </SeriesNutCaption>
      </Container>
    </SeriesNut>

    <Hilight>
      <Container>
        <HilightVideo>
          <Video poster={CoverHilight} controls>
            <source src={VideoHilight} type="video/mp4" />
          </Video>
        </HilightVideo>

        <HilightContent>
          <HilightContentArticle>
            <HilightContentRow>
              <HilightContentHeading>ตัวแทนหุ้นลม</HilightContentHeading>
              <HilightContentBrand>
                <ImageResponsive
                  source="alltopaffiliate-brand.png"
                  alt="ALLTOP AFFLIATE"
                  fadeIn
                />
              </HilightContentBrand>

              <HilightContentText>มาแต่ตัวก็รวยได้</HilightContentText>
            </HilightContentRow>

            <HilightContentRow>
              <HilightContentText>
                <HilightContentItalic>
                  ตัวแทนหุ้นลม เป็น ธุรกิจ ที่จะมาเพิ่มโอกาสและ
                </HilightContentItalic>

                {' '}

                <HilightContentUnderline>
                  สร้างรายได้
                  ให้กับคุณ โดย ปราศจากความเสี่ยง ไม่ต้องลงทุน
                  มีทั้งรายได้ และเงินเดือน
                </HilightContentUnderline>

                {' '}

                เพียงแค่คุณสมัครเข้าร่วม  เป็นสมาชิกครอบครัวเดียวกับเรา
              </HilightContentText>
            </HilightContentRow>

            <HilightContentSlogan>
              “เราดูแลคุณเหมือนครอบครัวเดียวกัน
              สนใจเป็นครอบครัวเดียวกับเรา”
            </HilightContentSlogan>
          </HilightContentArticle>
        </HilightContent>
      </Container>
    </Hilight>

    <Info>
      <Container>
        <InfoVideo>
          <Video poster={CoverInfo} controls>
            <source src={VideoInfo} type="video/mp4" />
          </Video>
        </InfoVideo>

        <InfoContent>
          <InfoContentImage>
            <ImageResponsive
              source="affiliate-section--info.png"
              alt="ALLTOP AFFLIATE"
              fadeIn
            />
          </InfoContentImage>
        </InfoContent>
      </Container>
    </Info>

    <Provider>
      <Container>
        <ProviderContent>
          <ProviderContentArticle>
            <ProviderContentTitle>เว็บไซต์ที่เรามีให้คุณ</ProviderContentTitle>

            <ProviderContentDesc>
              <ProviderContentDescHilight>
                ยินดีต้อนรับสู่ ALLTOP ASIA เว็บพนันออนไลน์ E-Wallet ที่ใหญ่ที่สุดในเอเชีย
              </ProviderContentDescHilight>

              ศูนย์รวมเว็บพนันออนไลน์ ที่รวม 15 เว็บ ไว้ในแพลตฟอร์มเดียวประกอบด้วย
              คาสิโน สล็อต กีฬา และอีสปอร์ต ครั้งแรกในประเทศไทย
              ขับเคลื่อนด้วยนวัตกรรม E-Wallet ครอบคลุมทุกการฝาก-ถอน
              ด้วยเทคโนโลยีล้ำสมัย ที่คุณสามารถโอนย้ายเครดิตด้วยตัวเองได้ทันที
            </ProviderContentDesc>
          </ProviderContentArticle>
        </ProviderContent>

        <ProviderApp>
          <ProviderAppImage>
            <ImageResponsive
              source="affiliate-provider--button.png"
              alt="ALLTOP Providers"
              fadeIn
            />
          </ProviderAppImage>
        </ProviderApp>
      </Container>
    </Provider>

    <Queations>
      <Container>
        <QueationsLists>
          <QueationsTitle>คำถามที่พบบ่อย?</QueationsTitle>

          <QueationsInteractive>
            <QueationsInteractiveRow>
              <QueationsInteractiveArrow>
                <ImageResponsive
                  source="affiliate-section-arrow.png"
                  alt="ALLTOP Providers"
                  fadeIn
                />
              </QueationsInteractiveArrow>

              <QueationsInteractiveTitle>
                1. ระบบเอเย่นค์หุ้นลม
              </QueationsInteractiveTitle>

              <QueationsInteractivePlus />
            </QueationsInteractiveRow>

            <QueationsInteractiveRow>
              <QueationsInteractiveArrow>
                <ImageResponsive
                  source="affiliate-section-arrow.png"
                  alt="ALLTOP Providers"
                  fadeIn
                />
              </QueationsInteractiveArrow>

              <QueationsInteractiveTitle>
                2. เครื่องมือการตลาด
              </QueationsInteractiveTitle>

              <QueationsInteractivePlus />
            </QueationsInteractiveRow>

            <QueationsInteractiveRow>
              <QueationsInteractiveArrow>
                <ImageResponsive
                  source="affiliate-section-arrow.png"
                  alt="ALLTOP Providers"
                  fadeIn
                />
              </QueationsInteractiveArrow>

              <QueationsInteractiveTitle>
                3. ผลตอบแทน
              </QueationsInteractiveTitle>

              <QueationsInteractivePlus />
            </QueationsInteractiveRow>

            <QueationsInteractiveRow>
              <QueationsInteractiveArrow>
                <ImageResponsive
                  source="affiliate-section-arrow.png"
                  alt="ALLTOP Providers"
                  fadeIn
                />
              </QueationsInteractiveArrow>

              <QueationsInteractiveTitle>
                4. บัญชีผู้ใช้
              </QueationsInteractiveTitle>

              <QueationsInteractivePlus />
            </QueationsInteractiveRow>

            <QueationsInteractiveRow>
              <QueationsInteractiveArrow>
                <ImageResponsive
                  source="affiliate-section-arrow.png"
                  alt="ALLTOP Providers"
                  fadeIn
                />
              </QueationsInteractiveArrow>

              <QueationsInteractiveTitle>
                5. ข้อกำหนดและเงื่อนไข
              </QueationsInteractiveTitle>

              <QueationsInteractivePlus />
            </QueationsInteractiveRow>
          </QueationsInteractive>
        </QueationsLists>
      </Container>
    </Queations>

    <Howto>
      <ImageBackground fadeIn source="affiliate-section--howto.jpg">
        <Container>
          <HowtoInner>
            <HowtoTitle>
              เครือข่ายตัวแทนหุ้นลม ALLTOP AFFLIATE ทำงานอย่างไร
            </HowtoTitle>

            <HowtoSubtitle>
              แค่มีมือถือ ก็เป็นเจ้าของธุรกิจได้ เพียงแค่ 3 ขั้นตอน
            </HowtoSubtitle>

            <HowtoStep>
              <HowtoStepList>
                <HowtoStepListNumber>
                  <HowtoStepListNumberText>1</HowtoStepListNumberText>
                </HowtoStepListNumber>

                <HowtoStepListText>
                  <HowtoStepListTextTitle>
                    สมัครสมาชิก
                  </HowtoStepListTextTitle>

                  <HowtoStepListTextDesc>
                    รอรับการอนุมัติบัญชีของคุณ
                  </HowtoStepListTextDesc>
                </HowtoStepListText>
              </HowtoStepList>

              <HowtoStepList>
                <HowtoStepListNumber>
                  <HowtoStepListNumberText>2</HowtoStepListNumberText>
                </HowtoStepListNumber>

                <HowtoStepListText>
                  <HowtoStepListTextTitle>
                    มีที่ปรึกษาแนะนำ
                  </HowtoStepListTextTitle>

                  <HowtoStepListTextDesc>
                    เทคนิคในการหาสมาชิก
                    ไม่เคยทำ ไม่ใช่ปัญหา
                    เรามีพี่เลี้ยงคอยให้คำแนะนำ
                  </HowtoStepListTextDesc>
                </HowtoStepListText>
              </HowtoStepList>

              <HowtoStepList>
                <HowtoStepListNumber>
                  <HowtoStepListNumberText>3</HowtoStepListNumberText>
                </HowtoStepListNumber>

                <HowtoStepListText>
                  <HowtoStepListTextTitle>
                    รับผลตอบแทน
                  </HowtoStepListTextTitle>

                  <HowtoStepListTextDesc>
                    รับค่าคอมมิชชั่นและเงินเดือนรวมถึง
                    สิทธิพิเศษดีๆอีกมากมาย
                  </HowtoStepListTextDesc>
                </HowtoStepListText>
              </HowtoStepList>
            </HowtoStep>

            <HowtoRegister>
              <HowtoRegisterTitle>ร่วมธุรกิจกับเรา</HowtoRegisterTitle>

              <HowtoRegisterButton href="https://dash.alltopaff.com/signup" target="_blank" rel="noopener noreferrer">
                <ImageBackground fadeIn source="affiliate-button--gold.png">
                  <HowtoRegisterButtonInner>
                    สมัครเลย
                  </HowtoRegisterButtonInner>
                </ImageBackground>
              </HowtoRegisterButton>
            </HowtoRegister>
          </HowtoInner>
        </Container>
      </ImageBackground>
    </Howto>
  </State>
);

export default Allaffiliate;
