import React from 'react';
import { ImageResponsive } from 'utils';
import { Link, IntlContextConsumer } from 'gatsby-plugin-intl';
import State from './styled';

const Hilight = () => (
  <State
    arrows={false}
    arrowsBlock={false}
    autoplaySpeed={5000}
    dots={false}
    duration={750}
    autoplay
  >
    <div>
      <a
        href="https://allmoviefun.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResponsive
          source="banner-alltopasia-allmoviefun.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </a>
    </div>

    <div>
      <Link to="/promotions/cashback">
        <ImageResponsive
          source="banner-alltopasia-cashback-0.13.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>

    <div>
      <Link to="/promotions/cashback">
        <ImageResponsive
          source="banner-alltopasia-cashback-6.5.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>

    <div>
      <Link to="/promotions/pragmatic-play-daily-wins-cash-drop">
        <ImageResponsive
          source="pragmatic.png"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>

    <IntlContextConsumer>
      {({ language: currentLanguage }) =>
        currentLanguage === 'en' ? (
          <div>
            <ImageResponsive
              source="joker-provider-english.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </div>
        ) : (
          <div>
            <ImageResponsive
              source="joker-provider-thai.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </div>
        )
      }
    </IntlContextConsumer>

    <div>
      <Link to="/promotions/allaffiliate">
        <ImageResponsive
          source="hilight-banner--2.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>

    <div>
      <Link to="/promotions/allrewards">
        <ImageResponsive
          source="hilight-banner--4.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>
  </State>
);

export default Hilight;
