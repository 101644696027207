import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
} from './styled';

const PromotionDetail16 = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--016.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'Slots & Games Daily Bonus 10% Unlimited'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'Slots & Games Daily Bonus 10% Unlimited'
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'Slots & Games Daily Bonus 10% Unlimited'
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'Slots & Games Daily Bonus 10% Unlimited'
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'ฝากขั้นต่ำ 300 บาท รับโบนัสทันที 10% Unlimited'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'ฝากขั้นต่ำ 300 บาท รับโบนัสทันที 10% Unlimited'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' && '918Kiss, Mega888, Pussy888, Xe88'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' && '918Kiss, Mega888, Pussy888, Xe88'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'ยอดฝาก + โบนัสที่ได้รับ x (4,6) = ยอดกำไรที่ต้องทาจึงจะถอนได้ (รวมทุน)'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'ยอดฝาก + โบนัสที่ได้รับ x (4,6) = ยอดกำไรที่ต้องทาจึงจะถอนได้ (รวมทุน)'
              }
            </IntlContextConsumer>
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินเข้าเว็บ ALLTOP ASIA
                    แล้วอย่างน้อย 1 ครั้ง จึงจะมีคุณสมบัติรับโบนัสในโปรโมชั่นนี้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
                  </TermList>
                  <TermList>
                    สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง
                  </TermList>
                  <TermList>
                    ต้องทำยอดเทิร์นโอเวอร์ 4 เท่ารวมทุน จึงจะสามารถถอนได้
                  </TermList>
                  <TermList>
                    หากสมาชิกมีการเดิมพัน Virtual Casino (FGG Casino)
                    เงื่อนไขจะเปลี่ยนเป็นโปรโมชั่น Virtual Casino ทันที
                    ต้องทำเทิร์นโอเวอร์ 15 เท่า จึงจะทำการถอนได้
                  </TermList>
                  <TermList>
                    ผลเสมอ, เดิมพัน 2 ฝั่ง, บิลรีเจ็ค, ยกเลิกเกมส์
                    จะไม่นับรวมในการคิดยอดเทิร์นโอเวอร์
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ
                    ALLTOP ASIA เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
                    รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
                  </TermList>
                  <TermList>
                    หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์
                    สามารถเลือกไม่รับโปรโมชั่นได้
                  </TermList>
                  <TermList>
                    หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ
                    ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
                    ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
                  </TermList>
                  <TermList>
                    กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ
                    ALLTOP ASIA
                    ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
                  </TermList>
                  <TermList>
                    ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
                    ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </TermList>
                  <TermList>
                    ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
                    บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
                    แอดเดรสเดียวเท่านั้น
                  </TermList>
                  <TermList>
                    หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
                    สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
                    จึงจะสามารถทำรายการถอนได้
                  </TermList>
                  <TermList>
                    การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด
                  </TermList>
                  <TermList>
                    ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 01/03/2020 เป็นต้นไป
                  </TermList>
                </>
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินเข้าเว็บ ALLTOP ASIA
                    แล้วอย่างน้อย 1 ครั้ง จึงจะมีคุณสมบัติรับโบนัสในโปรโมชั่นนี้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
                  </TermList>
                  <TermList>
                    สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง
                  </TermList>
                  <TermList>
                    ต้องทำยอดเทิร์นโอเวอร์ 4 เท่ารวมทุน จึงจะสามารถถอนได้
                  </TermList>
                  <TermList>
                    หากสมาชิกมีการเดิมพัน Virtual Casino (FGG Casino)
                    เงื่อนไขจะเปลี่ยนเป็นโปรโมชั่น Virtual Casino ทันที
                    ต้องทำเทิร์นโอเวอร์ 15 เท่า จึงจะทำการถอนได้
                  </TermList>
                  <TermList>
                    ผลเสมอ, เดิมพัน 2 ฝั่ง, บิลรีเจ็ค, ยกเลิกเกมส์
                    จะไม่นับรวมในการคิดยอดเทิร์นโอเวอร์
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ
                    ALLTOP ASIA เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
                    รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
                  </TermList>
                  <TermList>
                    หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์
                    สามารถเลือกไม่รับโปรโมชั่นได้
                  </TermList>
                  <TermList>
                    หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ
                    ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
                    ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
                  </TermList>
                  <TermList>
                    กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ
                    ALLTOP ASIA
                    ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
                  </TermList>
                  <TermList>
                    ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
                    ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </TermList>
                  <TermList>
                    ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
                    บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
                    แอดเดรสเดียวเท่านั้น
                  </TermList>
                  <TermList>
                    หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
                    สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
                    จึงจะสามารถทำรายการถอนได้
                  </TermList>
                  <TermList>
                    การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด
                  </TermList>
                  <TermList>
                    ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 01/03/2020 เป็นต้นไป
                  </TermList>
                </>
              )
            }
          </IntlContextConsumer>
        </TermDesc>
      </Term>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'สามารถรับได้ไม่จำกัดโบนัส และไม่จำกัดครั้ง'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'สามารถรับได้ไม่จำกัดโบนัส และไม่จำกัดครั้ง'
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionDetail16;
