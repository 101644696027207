/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { calcRem, Iconsvg } from 'utils';

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
  border-radius: ${calcRem(4)};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.type === 'gold' && css`
    background: ${props.theme.palette.goldPlate};

    ${Icon} {
      fill: ${props.theme.palette.black};
    };

    ${Title} {
      color: ${props.theme.palette.black};
    };
  `};

  ${(props) => props.type === 'silver' && css`
    background: ${props.theme.palette.silverPlate};

    ${Icon} {
      fill: ${props.theme.palette.white};
    };

    ${Title} {
      color: ${props.theme.palette.white};
    };
  `};

  ${(props) => props.type === 'border' && css`
    border: ${calcRem(1)} solid ${props.theme.palette.border};
  `};
`;

const Blank = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
  border-radius: ${calcRem(4)};

  @media (max-width: 575px) {
    padding: ${calcRem(8)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.type === 'gold' && css`
    background: ${props.theme.palette.goldPlate};

    ${Icon} {
      fill: ${props.theme.palette.black};
    };

    ${Title} {
      color: ${props.theme.palette.black};
    };
  `};

  ${(props) => props.type === 'silver' && css`
    background: ${props.theme.palette.silverPlate};

    ${Icon} {
      fill: ${props.theme.palette.white};
    };

    ${Title} {
      color: ${props.theme.palette.white};
    };
  `};

  ${(props) => props.type === 'border' && css`
    border: ${calcRem(1)} solid ${props.theme.palette.border};
  `};
`;

const Icon = styled(Iconsvg)`
  @media (max-width: 575px) {
    width: ${calcRem(28)};
    height: ${calcRem(28)};
    margin: 0 ${calcRem(4)} 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(24)};
    height: ${calcRem(24)};
    margin: 0 ${calcRem(2)} 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(24)};
    height: ${calcRem(24)};
    margin: 0 ${calcRem(2)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(24)};
    height: ${calcRem(24)};
    margin: 0 ${calcRem(2)} 0 0;
  }

  @media (min-width: 1200px) {
    width: ${calcRem(24)};
    height: ${calcRem(24)};
    margin: 0 ${calcRem(2)} 0 0;
  }
`;

const Title = styled.span`
  display: block;
  transform: translate(0, ${calcRem(1)});
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 575px) {
    margin: 0 0 0 ${calcRem(4)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 0 ${calcRem(2)};
    font-size: ${calcRem(20)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 0 ${calcRem(2)};
    font-size: ${calcRem(20)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 0 ${calcRem(2)};
    font-size: ${calcRem(20)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 0 ${calcRem(2)};
    font-size: ${calcRem(20)};
  }
`;

export {
  Button,
  Blank,
  Icon,
  Title,
};
