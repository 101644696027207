import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Claim,
  ClaimButton,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--002.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && 'สุ่มแจกประกัน Covid 19 ทุกวัน')
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && 'สุ่มแจกประกัน Covid 19 ทุกวัน')
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && 'สิทธิ์ประโยชน์พิเศษเฉพาะลูกค้า ALLTOP ร่วมต้าน Covid-19')
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && 'สิทธิ์ประโยชน์พิเศษเฉพาะลูกค้า ALLTOP ร่วมต้าน Covid-19')
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      {/*
      <Claim>
        <ClaimButton>
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && <ButtonTheme blank="https://app.alltopasia.com/login" icon="money" title="รับโบนัส" type="gold" />)
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && <ButtonTheme blank="https://app.alltopasia.com/login" icon="money" title="Claim Bonus" type="gold" />)
            }
          </IntlContextConsumer>
        </ClaimButton>
      </Claim>
      */}

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && 'รายละเอียด')
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && 'Details')
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {
                ({ language: currentLanguage }) => (currentLanguage === 'th' && 'สุ่มแจกประกัน Covid-19 วันละ 10 รางวัล รางวัลละ 600 บาท โดยทางเว็บจะสุ่มผ่าน ALLTOP Slot เท่านั้น')
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {
                ({ language: currentLanguage }) => (currentLanguage === 'en' && 'สุ่มแจกประกัน Covid-19 วันละ 10 รางวัล รางวัลละ 600 บาท โดยทางเว็บจะสุ่มผ่าน ALLTOP Slot เท่านั้น')
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && 'หมายเหตุ: ')
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && 'Note: ')
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && 'ด้วยความห่วงใยสมาชิกทุกท่าน จาก ALLTOP ASIA')
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && 'ด้วยความห่วงใยสมาชิกทุกท่าน จาก ALLTOP ASIA')
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && <ButtonTheme to="/promotions" icon="promotion" title="โปรโมชั่นทั้งหมด" type="silver" />)
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && <ButtonTheme to="/promotions" icon="promotion" title="More Promotions" type="silver" />)
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
