import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
} from './styled';

const PromotionDetail16 = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--020.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'E-Sport Cashback'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'E-Sport Cashback'}
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th'
              && 'E-Sport Cashback คืนยอดเสียรายสัปดาห์ 5% 10% Unlimited'}
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en'
              && 'E-Sport Cashback คืนยอดเสียรายสัปดาห์ 5% 10% Unlimited'}
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      {/* <Provider>
        <ProviderTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "เว็บไซต์ที่เข้าร่วมโปรโมชั่น"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "Providers Participate in Promotions"
            }
          </IntlContextConsumer>
        </ProviderTitle>

        <ProviderList>
          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-sport--nova88.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>Nova 88</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-sport--sbobet.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>Sbobet</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-sport--ts911.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>TS 911</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-sport--ufabet.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>UFA Bet</ProviderListAppTitle>
          </ProviderListApp>
        </ProviderList>
      </Provider>

      <Claim>
        <ClaimButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && (
                <ButtonTheme
                  blank="https://app.alltopasia.com/login"
                  icon="money"
                  title="รับโบนัส"
                  type="gold"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && (
                <ButtonTheme
                  blank="https://app.alltopasia.com/login"
                  icon="money"
                  title="Claim Bonus"
                  type="gold"
                />
              )
            }
          </IntlContextConsumer>
        </ClaimButton>
      </Claim>
      */}

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'รายละเอียด'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'Details'}
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) => currentLanguage === 'th'
                && 'สามารถรับยอดเงินคืนได้ที่ Allspin '}
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) => currentLanguage === 'en'
                && 'สามารถรับยอดเงินคืนได้ที่ Allspin '}
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'How to calculate turnover'}
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) => currentLanguage === 'th'
                && 'ไม่มีการทำเทิร์นโอเวอร์หรือการทำยอดกำไรใดๆ ทั้งสิ้น'}
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) => currentLanguage === 'en'
                && ' ไม่มีการทำเทิร์นโอเวอร์หรือการทำยอดกำไรใดๆ ทั้งสิ้น'}
            </IntlContextConsumer>
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'Terms and conditions'}
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินกับ Alltopasia แล้วอย่างน้อย 1 ครั้ง จึงจะมีคุณสมบัติรับโปรโมชั่นนี้</TermList>
          <TermList>สามารถทำการถอนได้เลย ไม่มีการทำเทิร์นโอเวอร์หรือการทำยอดกำไรใดๆ ทั้งสิ้น</TermList>
          <TermList>สมาชิกไม่ต้องขอรับโปรโมชั่นนี้ ระบบจะทำการคืนยอดเสียให้อัตโนมัติทุกสัปดาห์</TermList>
          <TermList>สมาชิกจะได้รับยอดเงินคืนทาง Allspin ในวันศุกร์ของทุกสัปดาห์ ตั้งแต่เวลา 15.00 น. ตามเวลาประเทศไทย เป็นต้นไป</TermList>
          <TermList>เงินจะเข้า กระเป๋า(Wallet) ของสมาชิกทันทีหลังจากการปั่น Allspin</TermList>
          <TermList>Alltopasia จะคำนวนยอดเงินคืนที่มีผลของการชนะหรือแพ้ที่แน่นอนแล้วเท่านั้น </TermList>
          <TermList>โปรโมชั่นนี้สามารถใช้ร่วมกับโปรโมชั่นอื่นๆได้</TermList>
          <TermList>โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ Alltopasia เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที</TermList>
          <TermList>กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ Alltopasia ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที</TermList>
          <TermList>Alltopasia ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</TermList>
          <TermList>ทุกโปรโมชั่นของ Alltopasia จำกัดสำหรับ 1 บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP แอดเดรสเดียวเท่านั้น</TermList>
          <TermList>ารตัดสินของ Alltopasia ถือเป็นที่สิ้นสุด</TermList>
        </TermDesc>
      </Term>
      {/* 
      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'หมายเหตุ: '}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'Note: '}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th'
              && 'สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en'
              && 'สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น'}
          </IntlContextConsumer>
        </NoteContent>
      </Note> */}

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && (
              <ButtonTheme
                to="/promotions"
                icon="promotion"
                title="โปรโมชั่นทั้งหมด"
                type="silver"
              />
            )}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && (
              <ButtonTheme
                to="/promotions"
                icon="promotion"
                title="More Promotions"
                type="silver"
              />
            )}
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionDetail16;
