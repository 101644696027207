import styled from 'styled-components';
import { Link } from 'gatsby';
import { calcRem, Iconsvg } from 'utils';

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 ${calcRem(8)};
  transition: all 1s ease;
  background: ${(props) => props.theme.palette.goldPlate};
  border-bottom: ${calcRem(1)} solid ${(props) => props.theme.palette.themeLight};
`;

const Blank = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
  background: ${(props) => props.theme.palette.goldPlate};
  border-bottom: ${calcRem(1)} solid ${(props) => props.theme.palette.themeLight};
`;

const Icon = styled(Iconsvg)`
  width: ${calcRem(42)};
  height: ${calcRem(42)};
  margin: 0 ${calcRem(4)} 0 0;
`;

const Title = styled.span`
  display: block;
  margin: 0 0 0 ${calcRem(4)};
  white-space: nowrap;
  color: ${(props) => props.theme.palette.black};
  font-size: ${calcRem(20)};
  font-weight: 500;
  text-transform: uppercase;
`;

export {
  Button,
  Blank,
  Icon,
  Title,
};
