import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  
  @media (max-width: 767px) {
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 768px) {
    padding: 0 ${calcRem(32)};
  }
`;

const Container = (props) => {
  const { children } = props;

  return (
    <State>
      {children}
    </State>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
