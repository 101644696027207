import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Roll,
  Blank,
  Row,
  Slot,
  Title,
} from './styled';

const ButtonSlot = (props) => {
  const {
    className,
    blank,
    icon,
    title,
    to,
  } = props;

  if (blank) {
    return (
      <Blank href={blank} target="_blank" rel="noopener noreferrer">
        <Roll>
          <Slot>
            <Row>
              <Icon icon={icon} default />
            </Row>

            <Row>
              <Icon icon="roulette" default />
            </Row>

            <Row>
              <Icon icon="slot" default />
            </Row>

            <Row>
              <Icon icon="sport" default />
            </Row>

            <Row>
              <Icon icon="esport" default />
            </Row>

            <Row>
              <Icon icon="promotion" default />
            </Row>

            <Row>
              <Icon icon="reward" default />
            </Row>

            <Row>
              <Icon icon="event" default />
            </Row>

            <Row>
              <Icon icon="money" default />
            </Row>

            <Row>
              <Icon icon={icon} hover />
            </Row>
          </Slot>
        </Roll>

        <Title>
          {title}
        </Title>
      </Blank>
    );
  }

  return (
    <Button className={className} to={to}>
      <Roll>
        <Slot>
          <Row>
            <Icon icon={icon} default />
          </Row>

          <Row>
            <Icon icon="roulette" default />
          </Row>

          <Row>
            <Icon icon="slot" default />
          </Row>

          <Row>
            <Icon icon="sport" default />
          </Row>

          <Row>
            <Icon icon="esport" default />
          </Row>

          <Row>
            <Icon icon="promotion" default />
          </Row>

          <Row>
            <Icon icon="reward" default />
          </Row>

          <Row>
            <Icon icon="event" default />
          </Row>

          <Row>
            <Icon icon="money" default />
          </Row>

          <Row>
            <Icon icon={icon} hover />
          </Row>
        </Slot>
      </Roll>

      <Title>
        {title}
      </Title>
    </Button>
  );
};

ButtonSlot.propTypes = {
  className: PropTypes.string,
  blank: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

ButtonSlot.defaultProps = {
  className: null,
  blank: null,
  to: null,
};

export default ButtonSlot;
