import styled from 'styled-components';
import Slider from 'infinite-react-carousel';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  height: auto;
`;

const Jackpot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: ${calcRem(16)} 0;
  border-bottom: solid  ${calcRem(1)} ${(props) => props.theme.palette.border};
  font-size: ${calcRem(36)};
  font-weight: 500;
  text-transform: uppercase;
`;

const Carousel = styled(Slider)`
  width: 100%;
  height: auto;
  padding: ${calcRem(16)} 0;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
`;

const CardCover = styled.div`
  width: calc(50% - ${calcRem(8)});
  margin: 0 ${calcRem(4)} 0 0;
`;

const CardCoverBlank = styled.a`
  display: block;
`;

const CardStat = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(50% - ${calcRem(8)});
  margin: 0 0 0 ${calcRem(4)};

  @media (max-width: 575px) {
    justify-content: center;
  }
  
  @media (min-width: 576px) {
    justify-content: space-between;
  }
`;

const CardStatDesc = styled.div`
  display: block;
`;

const CardStatTitle = styled.span`
  display: flex;
  align-items: flex-end;
  margin: 0 0 ${calcRem(6)};
`;

const CardStatName = styled.span`
  display: block;
  margin: 0 ${calcRem(2)} 0 0;
  font-size: ${calcRem(18)};
`;

const CardStatNumber = styled.span`
  display: block;
  margin: 0 0 0 ${calcRem(2)};
  font-size: ${calcRem(14)};
  transform: translate(0, ${calcRem(-1)});
`;

const CardStatReward = styled.span`
  display: block;
  line-height: 0.8;
  color: ${(props) => props.theme.palette.theme};
  font-size: ${calcRem(32)};
  font-weight: 700;
  margin: 0 0 ${calcRem(4)};
`;

const CardStatProvider = styled.span`
  display: block;
  font-size: ${calcRem(16)};
`;

const CardStatRequest = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
  
  @media (min-width: 576px) {
    display: flex;
  }
`;

const CardStatButton = styled.a`
  display: block;
  color: ${(props) => props.theme.palette.theme};
  font-size: ${calcRem(16)};
  white-space: nowrap;

  @media not all and (pointer: coarse) {
    &:hover {
      color: ${(props) => props.theme.palette.theme};
      text-decoration: underline;
    }
  }
`;

export {
  State,
  Card,
  CardCover,
  CardCoverBlank,
  CardStat,
  CardStatButton,
  CardStatDesc,
  CardStatName,
  CardStatNumber,
  CardStatProvider,
  CardStatRequest,
  CardStatReward,
  CardStatTitle,
  Carousel,
  Jackpot,
};
