const winners = {
  id_001: {
    user: '069784xxxx',
    reward: '฿2000',
    provider: '918Kiss',
    thumbnail: 'winner-provider--918kiss.png',
  },
  id_002: {
    user: '085456xxxx',
    reward: '฿500',
    provider: 'EZ Game',
    thumbnail: 'winner-provider--ezgame.png',
  },
  id_003: {
    user: '085963xxxx',
    reward: '฿3000',
    provider: 'EZ Game',
    thumbnail: 'winner-provider--ezgame.png',
  },
  id_004: {
    user: '098547xxxx',
    reward: '฿2000',
    provider: 'XE88',
    thumbnail: 'winner-provider--xe88.png',
  },
  id_005: {
    user: '087564xxxx',
    reward: '฿600',
    provider: 'Pussy888',
    thumbnail: 'winner-provider--pussy888.png',
  },
  id_006: {
    user: '094563xxxx',
    reward: '฿1000',
    provider: 'Joker',
    thumbnail: 'winner-provider--jokergaming.png',
  },
  id_007: {
    user: '085236xxxx',
    reward: '฿6000',
    provider: 'Pussy888',
    thumbnail: 'winner-provider--pussy888.png',
  },
  id_008: {
    user: '095874xxxx',
    reward: '฿2000',
    provider: 'EZ Game',
    thumbnail: 'winner-provider--ezgame.png',
  },
  id_009: {
    user: '069547xxxx',
    reward: '฿20000',
    provider: 'Sexy Barcarat',
    thumbnail: 'winner-provider--sexybacarat.png',
  },
  id_010: {
    user: '096365xxxx',
    reward: '฿500',
    provider: 'Mega888',
    thumbnail: 'winner-provider--mega888.png',
  },
  id_011: {
    user: '087542xxxx',
    reward: '฿8000',
    provider: 'XE88',
    thumbnail: 'winner-provider--xe88.png',
  },
  id_012: {
    user: '096874xxxx',
    reward: '฿6000',
    provider: 'Pussy888',
    thumbnail: 'winner-provider--pussy888.png',
  },
  id_013: {
    user: '064874xxxx',
    reward: '฿9000',
    provider: 'Mega888',
    thumbnail: 'winner-provider--mega888.png',
  },
  id_014: {
    user: '096845xxxx',
    reward: '฿15000',
    provider: 'EZ Game',
    thumbnail: 'winner-provider--ezgame.png',
  },
  id_015: {
    user: '098457xxxx',
    reward: '฿1500',
    provider: 'Sexy Barcarat',
    thumbnail: 'winner-provider--sexybacarat.png',
  },
  id_016: {
    user: '086549xxxx',
    reward: '฿6000',
    provider: 'Sexy Barcarat',
    thumbnail: 'winner-provider--sexybacarat.png',
  },
};

export default winners;
