import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Blank,
  Icon,
  Title,
} from './styled';

const ButtonHilight = (props) => {
  const {
    className,
    blank,
    icon,
    title,
    to,
  } = props;

  if (blank) {
    return (
      <Blank href={blank} target="_blank" rel="noopener noreferrer">
        <Icon icon={icon} />

        <Title>
          {title}
        </Title>
      </Blank>
    );
  }

  return (
    <Button className={className} to={to}>
      <Icon icon={icon} />

      <Title>
        {title}
      </Title>
    </Button>
  );
};

ButtonHilight.propTypes = {
  className: PropTypes.string,
  blank: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

ButtonHilight.defaultProps = {
  className: null,
  blank: null,
  to: null,
};

export default ButtonHilight;
