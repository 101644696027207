import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import State from './styled';

const ImageResponsive = (props) => {
  const {
    className,
    alt,
    fadeIn,
    loading,
    placeholder,
    source,
    title,
  } = props;

  const data = useStaticQuery(
    graphql` 
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                }
              }
            }
          }
        }
      }
    `,
  );

  const image = data.images.edges.find((n) => n.node.relativePath.includes(source));

  if (!image) {
    return null;
  }

  return (
    <State
      className={className}
      alt={alt}
      fadeIn={fadeIn}
      fluid={image.node.childImageSharp.fluid}
      loading={loading}
      placeholderStyle={placeholder ? { visibility: 'visible' } : { visibility: 'hidden' }}
      title={title}
    />
  );
};

ImageResponsive.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  fadeIn: PropTypes.bool,
  loading: PropTypes.string,
  placeholder: PropTypes.bool,
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ImageResponsive.defaultProps = {
  className: null,
  fadeIn: false,
  loading: 'lazy',
  title: null,
  placeholder: false,
};

export default ImageResponsive;
