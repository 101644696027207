import styled, { css } from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  height: auto;
  background-color: #181818;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const Hilight = styled.div`
  width: 100%;
  height: auto;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const Main = styled.div`
  width: 100%;
  max-width: 960px;
  height: auto;
  margin: 0 auto;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(64)} ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(64)} ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(64)} ${calcRem(32)};
  }
`;

const Headiing = styled.div`
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const HeadiingTitle = styled.h1`
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 ${calcRem(16)};

  @media (max-width: 575px) {
    font-size: ${calcRem(30)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(36)};
  }
`;

const HeadiingSubtitle = styled.p`
  margin: 0;
  line-height: 1.25;

  @media (max-width: 575px) {
    font-size: ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(24)};
  }
`;

const Detail = styled.div`
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const DetailTitle = styled.h2`
  margin: 0;
  font-weight: 500;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }
`;

const DetailDesc = styled.div`
  background-color: #000000;

  @media (max-width: 575px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(16)};
  }
`;

const DetailDescContent = styled.p`
  margin: 0;
  line-height: 1.25;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }
`;

const DetailDescContentLink = styled.a`
  text-decoration: underline;

  &:hover{
    text-decoration: none;
  }
`;

const Note = styled.div`
  text-align: center;
  
  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const NoteContent = styled.span`
  display: block;
  line-height: 1.25;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }
`;

const Provider = styled.div`
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
    font-size: ${calcRem(21)};
  }
`;

const ProviderTitle = styled.div`
  margin: 0;
  font-weight: 500;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(24)};
  }
`;

const ProviderList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ProviderListApp = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 575px) {
    width: calc(20% - ${calcRem(6.4)});
    margin: 0 ${calcRem(8)} 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: calc(20% - ${calcRem(6.4)});
    margin: 0 ${calcRem(8)} 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(20% - ${calcRem(12.8)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: calc(20% - ${calcRem(12.8)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
    width: calc(20% - ${calcRem(12.8)});
    margin: 0 ${calcRem(16)} 0 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(5n) {
    margin-right: 0;
  }

  ${(props) => props.marginBottom && css`
  @media (max-width: 575px) {
    margin: 0 ${calcRem(8)} ${calcRem(16)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 ${calcRem(8)} ${calcRem(16)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 ${calcRem(16)} ${calcRem(32)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 ${calcRem(16)} ${calcRem(32)} 0;
  }

  @media (min-width: 1200px) {
    margin: 0 ${calcRem(16)} ${calcRem(32)} 0;
  }
  `}
`;

const ProviderListAppCover = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: auto;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(8)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(8)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(8)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(8)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(8)};
  }
`;

const ProviderListAppTitle = styled.span`
  flex-shrink: 0;

  @media (max-width: 575px) {
    font-size: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
  }
`;

const Howto = styled.div`
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const HowtoTitle = styled.h3`
  margin: 0;
  font-weight: 500;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }
`;

const HowtoDesc = styled.div`
  background-color: green;

  @media (max-width: 575px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(16)};
  }
`;

const HowtoDescContent = styled.p`
  display: block;
  margin: 0;
  line-height: 1.25;
  color: #ffffff;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }
`;

const Term = styled.div`
  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const TermTitle = styled.h3`
  font-weight: 500;
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
    font-size: ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }
`;

const TermDesc = styled.ol`
  margin: 0;
  line-height: 1.25;
  background-color: #000000;

  @media (max-width: 575px) {
    padding: ${calcRem(32)} ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(32)} ${calcRem(64)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(32)} ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(32)} ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(32)} ${calcRem(64)};
  }
`;

const TermList = styled.li`
  margin: 0 0 ${calcRem(8)};

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(21)};
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

const More = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const MoreButton = styled.div`
  width: ${calcRem(182)};

  @media (max-width: 575px) {
    height: ${calcRem(48)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: ${calcRem(42)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: ${calcRem(42)};
  }

  @media (min-width: 1200px) {
    height: ${calcRem(42)};
  }
`;


const Claim = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(64)};
  }
`;

const ClaimButton = styled.div`
  width: ${calcRem(182)};

  @media (max-width: 575px) {
    height: ${calcRem(48)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: ${calcRem(42)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: ${calcRem(42)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: ${calcRem(42)};
  }

  @media (min-width: 1200px) {
    height: ${calcRem(42)};
  }
`;

export {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailDescContentLink,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
};
