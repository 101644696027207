import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { calcRem, Iconsvg } from 'utils';

const Button = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;
`;

const Blank = styled.a`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;
`;

const Roll = styled.span`
  display: block;
  width: ${calcRem(48)};
  height: ${calcRem(59)};
  overflow: hidden;
`;

const Slot = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 1s ease;

  ${Button}:hover & {
    transform: translate(0, calc(-100% + ${calcRem(59)} ));
  }

  ${Blank}:hover & {
    transform: translate(0, calc(-100% + ${calcRem(59)} ));
  }
`;

const Row = styled.span`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: ${calcRem(48)};
  height: ${calcRem(59)};
  position: relative;
`;

const Icon = styled(Iconsvg)`
  width: ${calcRem(48)};
  height: ${calcRem(48)};

  ${(props) => props.default && css`
    fill: ${props.theme.palette.base};
  `};

  ${(props) => props.hover && css`
    fill: ${props.theme.palette.theme};
  `};
`;

const Title = styled.span`
  display: block;
  transition: color 0.5s ease;
  width: 100%;
  line-height: 1.5;
  color: ${(props) => props.theme.palette.base};
  font-size: ${calcRem(20)};
  font-weight: 400;
  text-align: center;
  white-space: nowrap;

  ${Button}:hover & {
    color: ${(props) => props.theme.palette.theme};
  }

  ${Blank}:hover & {
    color: ${(props) => props.theme.palette.theme};
  }
`;

export {
  Button,
  Blank,
  Icon,
  Roll,
  Row,
  Slot,
  Title,
};
